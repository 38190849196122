<template>
  <div
    class="d-flex justify-content-between align-items-center ml-3 item"
    :class="
      activeConversation && activeConversation.sid === group.twilio_conv_id
        ? 'active'
        : ''
    "
  >
    <p
      class="mb-0 txt-black cursor-pointer max-line-1"
      :class="group && (group.unread_count || group.unread) ? 'fs-16 fw-700' : 'fs-14 fw-500'"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2"
      >
        <path
          d="M20 10V8H16V4H14V8H10V4H8V8H4V10H8V14H4V16H8V20H10V16H14V20H16V16H20V14H16V10H20ZM14 14H10V10H14V14Z"
          fill="#84818A"
        />
      </svg>
      {{ group && group.name }}
    </p>
    <div>
      <span
        class="noti-count"
        v-if="group && (group.unread_count || group.unread)"
      >{{ group && (group.unread_count || group.unread) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatGroupListItem',
  props: ['group', 'activeConversation'],
  data () {
    return {}
  },
  watch: {},
  mounted () {},
  methods: {}
}
</script>

<style scoped>
.noti-count {
  width: 24px;
  height: 24px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d92d20;
  text-align: center;
  color: white;
  border-radius: 12px;
  padding-top: 2px;
}
.item {
  padding: 10px;
}
.item {
  padding: 10px;
}
.item:hover,
.active {
  background-color: #e4e7ec;
}
</style>
