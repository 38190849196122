<template>
  <div></div>
</template>
<script>
import { Client as ConversationsClient } from '@twilio/conversations'
import Conversation from './Conversation.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { CHAT_CONVERSATION_TYPE } from '../../utils/constants'

export default {
  name: 'ChatCpn',
  components: { Conversation },
  data () {
    return {
      statusString: '',
      activeConversation: null,
      name: '',
      nameRegistered: false,
      isConnected: false,
      token: '',
      $conversationsClient: null,
      activeConversations: [],
      listCvs: [],
      listChats: [],
      loading_cvs: true,
      loading_chats: true,
      params: {
        type: CHAT_CONVERSATION_TYPE.GROUP,
        page_size: 10,
        page_num: 1,
        keyword: '',
        sort_by: 'unread',
        order: 'desc'
      }
    }
  },
  mounted () {
    // this.preloadChatList()
    this.$conversationsList = {
      group: this.$conversationsList?.group || [],
      direct: this.$conversationsList?.direct || [],
      loading: true,
      total_unread: 0
    }
  },
  watch: {
    $fcmToken () {
      this.registerFcm()
    },
    $user () {
      this.renewToken()
    },
    $reloadChat (data) {
      // console.log(data)
      // this.preloadChatList()
      this.getChatList()
    },
    '$conversationsList.total_unread' () {
      this.handlerIconTileHtml()
    },
    reloadDataChatList (data) {
      if (data) {
        // this.preloadChatList()
      }
    }
  },
  computed: {
    ...mapGetters({
      chatConversation: 'chatConversation/getAllChatListConversation',
      reloadDataChatList: 'chatConversation/getReloadDataChatListConversation'
    })
  },
  methods: {
    ...mapMutations({
      setReloadDataChatList: 'chatConversation/SET_RELOAD_DATA_CHAT_LIST'
    }),
    ...mapActions('chatConversation', ['getChatListData']),
    handlerIconTileHtml () {
      let count = false
      if (this.$conversationsList) {
        count = this.$conversationsList.total_unread
      }
      let title = 'HODO - Nền tảng bảo mật'
      let icon = process.env.VUE_APP_BASE_URL + 'hodologo_256x256_dot.png'
      if (count) {
        title = 'Bạn có tin nhắn mới!'
        icon = process.env.VUE_APP_BASE_URL + 'hodologo_256x256_dot.png'
      } else {
        title = 'HODO - Nền tảng bảo mật'
        icon = process.env.VUE_APP_BASE_URL + 'hodologo_256x256.png'
      }
      document.title = title
      var link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = icon
    },
    renewToken () {
      this.removeEventListener()
      this.getTwlCvsToken()
    },
    async getTwlCvsToken () {
      let self = this
      let params = {
        userType: 'DOCTOR',
        device: 'ANDROID_DOCTOR'
      }
      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .getTwlCvsToken(params)
          .then(r => {
            self.token = r.data.data
          })
        await this.initConversationsClient()
        // await this.preloadChatList()
        await this.getChatList()
      } catch (error) {
        console.log(error)
      }
    },
    async initConversationsClient () {
      try {
        if (!this.$user) {
          if (this.$conversationsClient) {
            this.$conversationsClient.shutdown()
          }
          return
        }
        let user = this.$user
        window.conversationsClient = ConversationsClient
        this.$conversationsClient = new ConversationsClient(this.token)
        this.statusString = 'Connecting to Twilio...'

        this.$conversationsClient.on(
          'connectionStateChanged',
          this.connectionStateChange
        )
        this.$conversationsClient.on(
          'conversationJoined',
          this.conversationJoined
        )
        this.$conversationsClient.on('messageAdded', this.messageAdded)
        this.$conversationsClient.on('tokenExpired', this.renewToken)
        this.$conversationsClient.user.updateAttributes({
          user_id: user?.id,
          name: user?.name,
          avatar: user?.avatar
        })
        this.registerFcm()
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    registerFcm () {
      if (this.$conversationsClient && this.$fcmToken) {
        this.$conversationsClient.setPushRegistrationId('fcm', this.$fcmToken)
      }
    },
    async preloadChatList (isNotLoading = false) {
      try {
        const [groupChatList, directChatList] = await Promise.all([
          this.getChatListV2(
            {
              ...this.params,
              type: CHAT_CONVERSATION_TYPE.GROUP
            },
            isNotLoading
          ),
          this.getChatListV2(
            {
              ...this.params,
              type: CHAT_CONVERSATION_TYPE.DIRECT
            },
            isNotLoading
          )
        ])

        this.$conversationsList = {
          ...this.$conversationsList,
          group: groupChatList?.data || [],
          direct: directChatList?.data || []
        }

        const totalUnread = this.$conversationsList.group
          .concat(this.$conversationsList.direct)
          .reduce((acc, chat) => acc + chat.unread, 0)

        this.$conversationsList = {
          ...this.$conversationsList,
          total_unread: totalUnread || 0
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.setReloadDataChatList(false)
      }
    },
    async getChatList (notLoading = false) {
      let self = this
      if (notLoading) {
        this.$conversationsList = {
          group: this.$conversationsList?.group || [],
          direct: this.$conversationsList?.direct || [],
          loading: !notLoading,
          total_unread: 0
        }
      }
      // console.log(this.$conversationsList.loading, 'chatcpn')
      try {
        self.loading_chats = true
        await this.$rf
          .getRequest('AuthRequest')
          .getChatList()
          .then(res => {
            this.$conversationsList = {
              group: res?.data?.group?.sort(this.sortCvsDate) || [],
              direct: res?.data?.direct.sort(this.sortCvsDate) || [],
              loading: false,
              total_unread: res?.data?.total_unread
            }
          })
      } catch (error) {
      } finally {
        this.loading_chats = false
      }
    },
    async getChatListV2 (params, isNotLoading = false) {
      let self = this
      if (isNotLoading) {
        this.$conversationsList = {
          group: this.$conversationsList?.group || [],
          direct: this.$conversationsList?.direct || [],
          loading: !isNotLoading,
          total_unread: 0
        }
      }
      try {
        self.loading_chats = true
        const res = await this.getChatListData(params)
        return res
      } catch (error) {
      } finally {
        this.loading_chats = false
      }
    },
    connectionStateChange (state) {
      switch (state) {
        case 'connected':
          this.statusString = 'You are connected.'
          this.isConnected = true
          break
        case 'disconnecting':
          this.statusString = 'Disconnecting from Twilio...'
          break
        case 'disconnected':
          this.statusString = 'Disconnected.'
          break
        case 'denied':
          this.statusString = 'Failed to connect.'
          break
      }
    },
    removeEventListener () {
      if (!this.$conversationsClient) return
      this.$conversationsClient.removeListener(
        'connectionStateChanged',
        this.connectionStateChange
      )
      this.$conversationsClient.removeListener(
        'conversationJoined',
        this.conversationJoined
      )
      this.$conversationsClient.removeListener(
        'messageAdded',
        this.messageAdded
      )
      this.$conversationsClient.removeListener('tokenExpired', this.renewToken)
    },
    async getUnreadCountByMessageCvs (m) {
      if (!m || parseInt(m.author.replace(/\D/g, '')) === this.$user?.id) { return }
      let c = m.conversation
      if (!c || this.$chattingCvstId === c.sid) return
      let finded = false
      let count = 0
      let idx = c.lastReadMessageIndex
      if (idx === null) {
        count = m.index ? m.index + 1 : 1
      } else {
        count = await c.getUnreadMessagesCount()
      }
      this.$conversationsList &&
        this.$conversationsList.direct.some(s => {
          if (s.twilio_conv_id === c.sid) {
            s.unread_count = count || 0
            finded = true
            return true
          }
        })
      if (!finded) {
        this.$conversationsList &&
          this.$conversationsList.group.some(s => {
            if (s.twilio_conv_id === c.sid) {
              s.unread_count = count || 0
              finded = true
              return true
            }
          })
      }
      if (!finded) {
        let data = await this.getInfoConversation(c.sid)
        if (data) {
          if (data.reference_type === 4) {
            this.$conversationsList.direct.unshift(data)
          } else if (data.reference_type === 2 || data.reference_type === 3) {
            this.$conversationsList.group.unshift(data)
          }
          this.getUnreadCountByMessageCvs(m)
        }
      } else {
        this.countTotalUnread()
      }
    },
    async getUnreadCountByMessageCvsV2 (m) {
      if (!m || parseInt(m.author.replace(/\D/g, '')) === this.$user?.id) {
        return
      }

      let c = m.conversation

      if (!c || this.$chattingCvstId === c.sid) return

      let finded = false
      let count = 0
      let idx = c.lastReadMessageIndex

      if (!idx || idx === null) {
        count = m.index ? m.index + 1 : 1
      } else {
        count = await c.getUnreadMessagesCount()
      }

      this.$conversationsList &&
        this.$conversationsList.direct.some(s => {
          if (s.twilio_conv_id === c.sid) {
            s.unread = count || 0
            finded = true
            return true
          }
        })

      if (!finded) {
        this.$conversationsList &&
          this.$conversationsList.group.some(s => {
            if (s.twilio_conv_id === c.sid) {
              s.unread = count || 0
              finded = true
              return true
            }
          })
      }

      if (!finded) {
        let data = await this.getInfoConversation(c.sid)
        if (data) {
          if (data.reference_type === CHAT_CONVERSATION_TYPE.DIRECT) {
            this.$conversationsList.direct.unshift(data)
          } else if (
            data.reference_type === CHAT_CONVERSATION_TYPE.GROUP ||
            data.reference_type === 3
          ) {
            this.$conversationsList.group.unshift(data)
          }
          this.getUnreadCountByMessageCvs(m)
        }
      } else {
        this.countTotalUnread()
      }
    },
    async getInfoConversation (id) {
      if (!id) return
      let data = await this.$rf
        .getRequest('AuthRequest')
        .getInfoConversation(id)
        .then(r => {
          return r.data
        })
      return data
    },
    countTotalUnread () {
      let count = 0
      count +=
        (this.$conversationsList &&
          this.$conversationsList.group &&
          this.$conversationsList.group.reduce(
            (n, { unread_count }) => n + unread_count,
            0
          )) ||
        0
      count +=
        (this.$conversationsList &&
          this.$conversationsList.direct &&
          this.$conversationsList.direct.reduce(
            (n, { unread_count }) => n + unread_count,
            0
          )) ||
        0
      this.$conversationsList.total_unread = count
    },
    countTotalUnreadV2 () {
      let count = 0
      count +=
        (this.$conversationsList &&
          this.$conversationsList.group &&
          this.$conversationsList.group.reduce(
            (n, { unread }) => n + unread,
            0
          )) ||
        0
      count +=
        (this.$conversationsList &&
          this.$conversationsList.direct &&
          this.$conversationsList.direct.reduce(
            (n, { unread }) => n + unread,
            0
          )) ||
        0
      this.$conversationsList.total_unread = count
    },
    messageAdded (m) {
      this.getUnreadCountByMessageCvs(m)
    },
    conversationJoined (e) {},
    conversationAdded (e) {},
    conversationLeft (e) {},
    conversationRemoved (e) {},
    participantUpdated (e) {},
    sortCvsDate (a, b) {
      if (
        (a.last_message_created_at
          ? window.moment(a.last_message_created_at).unix()
          : 0) <
        (b.last_message_created_at
          ? window.moment(b.last_message_created_at).unix()
          : 0)
      ) {
        return 1
      } else {
        return -1
      }
    }
  }
}
</script>
<style scoped>
.chat-overlay-wraper {
  position: fixed;
  z-index: 1039;
  bottom: 0;
  display: flex;
  right: 0;
  justify-content: flex-end;
  align-items: flex-end;
}
.opening-chat {
  width: 300px;
  height: 455px;
  background: white;
  box-shadow: 0 8px 8px 0 rgba(103, 151, 255, 0.11),
    0 12px 18px 0 rgba(103, 151, 255, 0.11);
  padding: 4px;
}
.list-chatting {
  width: 75px;
  height: 100vh;
}
</style>
