<template>
  <div class="media-wraper">
    <div @click="showModalView">
      <div v-if="loading" class="mb-2">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-if="has_failed && !loading" :style="'display: flex, flexDirection: column'">
        <!-- <Icon :type="'warning'" :style="'fontSize: 5em'" /> -->
        <p>Tải file thất bại</p>
      </div>
      <div v-if="!has_failed && url" class="my-2">
        <div :set="(type = checkType())">
          <div
            class="relative border rounded-md overflow"
            v-if="type === 'image'"
            @click="showImage(url)"
          >
            <ImageBox class="img-preview rounded-md bg-gray-100" :source="url" :alt="url" />
            <span
              class="absolute bottom-0 right-0 px-2 text-xs font-medium rounded-tl-md bg-blue-100 text-blue-800 uppercase"
            >{{ getImageFileType() }}</span>
          </div>

          <video v-if="type === 'video'" class="video-fluid" autoplay loop muted controls>
            <source :src="url" :type="media.contentType" />
          </video>
          <audio v-if="type === 'audio'" controls>
            <source :src="url" :type="media.contentType" />
          </audio>
          <div
            v-if="type === 'other'"
            class="d-flex p-3 w-100 align-items-center border radius-10 cursor-pointer"
            @click="download(url, media.filename)"
          >
            <div class="file-privew mr-2" v-if="type === 'other' && this.media">
              <span class="fs-5 text-white mt-1 text-uppercase" v-if="!downloading">
                {{
                this.media.filename && this.media.filename.split(".").pop()
                }}
              </span>
              <div class="spinner-border" role="status" v-if="downloading">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <p class="mb-0 fs-12 w-100 max-line-1">{{ this.media.filename }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="md">
      <div>
        <img class="img-fluid" :src="url" />
      </div>
    </div>-->
  </div>
</template>

<script>
import ImageBox from '@/components/Common/Image.vue'
import appUtils from '@/utils/appUtils'

export default {
  props: ['media'],
  components: { ImageBox },
  data () {
    return {
      url: '',
      has_failed: false,
      loading: false,
      downloading: false,
      appUtils
    }
  },
  watch: {
    media (m) {
      this.getMediaUrl()
    }
  },
  mounted () {
    this.getMediaUrl()
  },
  methods: {
    async getMediaUrl () {
      let self = this
      if (!this.media || !this.media.filename) return
      this.loading = true
      const isHEIC = this.media?.contentType === 'image/heic'
      await this.media
        .getContentTemporaryUrl()
        .then(async url => {
          if (!isHEIC) {
            self.url = url
            return
          }
          const urlData = await appUtils.convertHEIC(url)
          self.url = urlData
        })
        .catch(e => {
          self.has_failed = true
        })
        .finally(() => {
          self.loading = false
        })
    },
    showModalView () {},
    checkType () {
      if (this.isImage(this.media?.filename)) {
        return 'image'
      }
      if (this.isVideo(this.media?.filename)) {
        return 'video'
      }
      if (this.isAudio(this.media?.filename)) {
        return 'audio'
      }
      return 'other'
    },
    isVideo (file_name) {
      return (
        file_name?.toLowerCase().match(/\.(mp4|ogx|oga|ogv|ogg|webm)$/) != null
      )
    },
    isImage (file_name) {
      return (
        file_name?.toLowerCase().match(/\.(jpeg|jpg|gif|png|heic)$/) != null
      )
    },
    getImageFileType () {
      return this.media?.contentType.split('/')[1]
    },
    isAudio (file_name) {
      return (
        file_name?.toLowerCase().match(/\.(m4a|flac|mp3|wav|wma|aac)$/) != null
      )
    },
    download (url, filename) {
      this.downloading = true
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          this.downloading = false
        })
        .catch(console.error)
    },
    showImage (p) {
      this.$emit('showImage', p)
    },
    async getHEICImage () {}
  }
}
</script>

<style scoped>
.video-fluid {
  width: 100%;
  height: auto;
}
.file-privew {
  width: 32px;
  height: 32px;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../../public/assets/images/icon/file-preview-bg.svg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-wraper {
  width: 100%;
  max-width: 456px;
}
.img-preview {
  max-height: 300px;
  cursor: pointer;
}
</style>
