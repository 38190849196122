<template>
  <div class="py-3 container h-full flex flex-column">
    <div id="circleChat" class="p-3 pb-0 bg-white flex-1 h-full">
      <div class="d-flex items-center justify-content-between mb-2">
        <p
          class="flex items-center fs-16 fw-600 txt-black hover:text-primary transition-all duration-200 cursor-pointer mr-auto mb-0"
          @click="handleViewCircleDetail"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
          >
            <path
              d="M20 10V8H16V4H14V8H10V4H8V8H4V10H8V14H4V16H8V20H10V16H14V20H16V16H20V14H16V10H20ZM14 14H10V10H14V14Z"
              fill="currentColor"
            />
          </svg>
          <span
            class="text-truncate flex-1"
          >{{ propName ? propName : (activeConversation && activeConversation.friendlyName) }}</span>
        </p>
        <div v-if="this.circleID" @click="goToMeet" class="open-chat-att">
          <div class="d-flex align-items-center">
            <svg
              class="mx-2"
              width="24"
              height="20"
              viewBox="0 0 36 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.0683 4.672C33.3483 4.32 31.838 3.952 29.7834 5.264L27.202 6.928C27.0088 1.952 24.638 0 18.878 0H8.34146C2.33561 0 0 2.128 0 7.6V20.4C0 24.08 2.19512 28 8.34146 28H18.878C24.638 28 27.0088 26.048 27.202 21.072L29.7834 22.736C30.8722 23.44 31.8205 23.664 32.5756 23.664C33.2254 23.664 33.7346 23.488 34.0683 23.328C34.7883 22.992 36 22.08 36 19.792V8.208C36 5.92 34.7883 5.008 34.0683 4.672ZM16.2439 13.008C14.4351 13.008 12.9424 11.664 12.9424 10C12.9424 8.336 14.4351 6.992 16.2439 6.992C18.0527 6.992 19.5454 8.336 19.5454 10C19.5454 11.664 18.0527 13.008 16.2439 13.008Z"
                fill="#20419B"
              />
            </svg>

            <span class="txt-black fs-16 fw-500">{{ $t('circle_feature.lbl_circle_meet') }}</span>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="circle-chat-content">
        <Conversation
          v-if="activeConversation"
          :active-conversation="activeConversation"
          :circleID="circleID"
        />
        <div class="mt-custom text-center" v-if="!activeConversation">
          <p class="mb-1 fs-16 fw-500">{{ $t('chat_features.lbl_start_msg') }}</p>
          <svg
            width="456"
            height="485"
            viewBox="0 0 456 485"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.387512 218.79V274.346C0.387512 279.174 2.3054 283.804 5.71927 287.218C9.13314 290.632 13.7633 292.55 18.5913 292.55H217.783C222.609 292.547 227.236 290.628 230.648 287.214C234.059 283.8 235.975 279.172 235.975 274.346V227.408L260.779 201.356L18.6612 200.598C16.2658 200.589 13.8921 201.053 11.6762 201.963C9.46027 202.873 7.44575 204.211 5.74811 205.901C4.05047 207.591 2.70309 209.6 1.7832 211.811C0.863321 214.023 0.389029 216.395 0.387512 218.79V218.79Z"
              fill="#F5F5F5"
            />
            <path
              d="M206.611 234.254L25.8681 233.414L25.8798 227.863L206.635 228.691L206.611 234.254Z"
              fill="#E0E0E0"
            />
            <path
              d="M206.611 252.527L25.8681 251.699L25.8798 246.137L206.635 246.976L206.611 252.527Z"
              fill="#E0E0E0"
            />
            <path
              d="M135.107 264.434L135.08 269.985L206.6 270.334L206.627 264.784L135.107 264.434Z"
              fill="#E0E0E0"
            />
            <path
              d="M184.956 410.74V466.296C184.956 471.124 186.873 475.754 190.287 479.168C193.701 482.582 198.331 484.5 203.159 484.5H402.328C407.154 484.497 411.781 482.578 415.192 479.164C418.604 475.751 420.52 471.122 420.52 466.296V419.358L445.324 393.306L203.194 392.548C200.802 392.544 198.432 393.011 196.22 393.923C194.008 394.835 191.998 396.174 190.304 397.864C188.61 399.553 187.266 401.56 186.348 403.77C185.43 405.979 184.957 408.348 184.956 410.74V410.74Z"
              fill="#F5F5F5"
            />
            <path
              d="M392.054 424.758L211.299 423.93L211.311 418.367L392.066 419.207L392.054 424.758Z"
              fill="#E0E0E0"
            />
            <path
              d="M392.054 443.031L211.299 442.203L211.311 436.641L392.066 437.48L392.054 443.031Z"
              fill="#E0E0E0"
            />
            <path
              d="M320.532 454.926L320.505 460.477L392.025 460.827L392.052 455.276L320.532 454.926Z"
              fill="#E0E0E0"
            />
            <path
              d="M106.858 9.53455H224.337C227.12 9.53455 229.79 10.6403 231.758 12.6086C233.727 14.5769 234.832 17.2464 234.832 20.03V77.4867C234.832 80.2703 233.727 82.9398 231.758 84.9081C229.79 86.8764 227.12 87.9822 224.337 87.9822H124.654C121.87 87.9822 119.2 86.8764 117.232 84.9081C115.264 82.9398 114.158 80.2703 114.158 77.4867V16.7997L106.858 9.53455Z"
              fill="#F5F5F5"
            />
            <path
              d="M174.927 65.3936V102.244C174.927 105.445 173.655 108.515 171.392 110.779C169.128 113.042 166.058 114.314 162.857 114.314H30.7776C29.1891 114.319 27.6153 114.01 26.1462 113.406C24.6772 112.801 23.3417 111.913 22.2163 110.792C21.0909 109.671 20.1976 108.339 19.5876 106.872C18.9775 105.405 18.6628 103.833 18.6612 102.244V71.1078L2.26501 53.8253L162.857 53.3239C166.058 53.3239 169.128 54.5955 171.392 56.859C173.655 59.1225 174.927 62.1925 174.927 65.3936V65.3936Z"
              fill="#E0E0E0"
            />
            <path
              d="M38.1828 75.6442L158.064 75.0961L158.052 71.411L38.1711 71.9708L38.1828 75.6442Z"
              fill="#F5F5F5"
            />
            <path
              d="M38.1828 87.7722L158.064 87.2125L158.052 83.5391L38.1711 84.0872L38.1828 87.7722Z"
              fill="#F5F5F5"
            />
            <path d="M84.934 96.3901H37.4946V100.075H84.934V96.3901Z" fill="#F5F5F5" />
            <path
              d="M425.232 236.248V273.099C425.232 276.3 423.96 279.37 421.696 281.633C419.433 283.897 416.363 285.168 413.162 285.168H281.047C277.845 285.168 274.774 283.897 272.509 281.634C270.243 279.371 268.969 276.301 268.966 273.099V241.962L252.523 224.68L413.115 224.178C414.704 224.174 416.277 224.482 417.747 225.087C419.216 225.691 420.551 226.579 421.676 227.7C422.802 228.821 423.695 230.153 424.305 231.62C424.915 233.087 425.23 234.659 425.232 236.248Z"
              fill="#E0E0E0"
            />
            <path
              d="M288.452 246.51L408.345 245.95L408.322 242.265L288.441 242.825L288.452 246.51Z"
              fill="#F5F5F5"
            />
            <path
              d="M288.452 258.626L408.345 258.078L408.322 254.393L288.441 254.941L288.452 258.626Z"
              fill="#F5F5F5"
            />
            <path d="M334.364 268.48H286.925V272.166H334.364V268.48Z" fill="#F5F5F5" />
            <path
              d="M312.067 419.16C292.324 399.93 291.997 370.181 297.128 344.351C297.991 340.036 298.294 335.383 300.953 331.908C303.612 328.433 308.871 326.625 312.463 329.098C315.437 331.138 316.23 335.115 317.524 338.508C319.249 343.039 322.226 346.986 326.107 349.89C328.638 351.779 331.938 353.214 334.877 352.164C338.958 350.707 340.253 345.61 340.929 341.296C342.189 333.241 343.456 325.191 344.731 317.144C345.407 312.818 346.119 308.41 348.124 304.538C350.13 300.667 353.734 297.343 358.025 296.865C362.317 296.387 367.004 299.582 367.179 303.955C367.047 305.766 366.794 307.566 366.421 309.343C366.246 311.15 366.76 313.273 368.381 314.007C370.002 314.742 371.949 313.856 373.442 312.841C378.608 309.215 382.549 304.107 386.269 298.987C389.99 293.868 393.686 288.597 398.514 284.504C403.342 280.41 409.534 277.565 415.773 278.23C422.012 278.894 427.983 283.781 428.543 290.124C429.103 296.468 424.555 302.054 419.89 306.346C412.964 312.712 405.156 318.046 396.707 322.182C395.191 322.929 393.546 323.722 392.765 325.238C391.354 327.966 393.616 331.267 396.263 332.678C399.307 334.275 402.841 334.509 406.141 335.465C409.441 336.421 412.846 338.485 413.604 341.879C414.642 346.543 410.199 350.59 406.071 353.027C397.807 357.903 388.766 361.319 379.342 363.126C375.984 363.767 372.474 364.234 369.547 366.053C366.62 367.872 364.451 371.487 365.5 374.776C366.55 378.065 370.351 379.697 373.78 379.627C377.208 379.557 380.474 378.228 383.844 377.552C390.141 376.292 397.733 377.948 400.683 383.697C402.631 387.476 402.083 392.198 400.158 396.012C398.07 399.734 395.254 402.998 391.879 405.609C380.544 415.195 366.911 422.18 352.369 424.699C337.827 427.218 324.883 426.425 312.032 419.183"
              fill="#20419B"
            />
            <path
              d="M308.568 438.379C311.734 426.524 316.03 415 321.396 403.965C328.029 390.515 335.427 377.456 343.553 364.852L349.897 354.905C351.985 351.639 354.049 348.398 356.276 345.366C360.56 339.528 365.166 333.934 370.072 328.608C374.736 323.488 379.284 318.812 383.611 314.462C387.937 310.113 392.042 306.159 395.949 302.707C402.073 297.22 408.624 292.229 415.54 287.781C417.989 286.206 419.925 285.04 421.243 284.282L422.759 283.407C422.936 283.296 423.123 283.202 423.319 283.128C423.166 283.258 423.002 283.375 422.829 283.477L421.359 284.41C420.065 285.215 418.164 286.416 415.75 288.037C408.913 292.569 402.43 297.613 396.357 303.127C392.485 306.626 388.415 310.579 384.112 314.929C379.809 319.279 375.261 323.99 370.655 329.098C365.799 334.411 361.236 339.986 356.987 345.797C354.783 348.817 352.731 352.036 350.643 355.301C348.556 358.567 346.457 361.89 344.311 365.249C336.21 377.832 328.813 390.854 322.154 404.257C316.763 415.234 312.413 426.693 309.163 438.483"
              fill="#263238"
            />
            <path
              d="M356.544 345.366C356.441 344.734 356.402 344.093 356.427 343.453C356.427 342.229 356.427 340.444 356.427 338.252C356.521 333.856 356.859 327.757 357.244 321.098C357.628 314.439 357.897 308.363 357.92 303.967C357.92 301.775 357.92 300.002 357.92 298.778C357.869 298.139 357.893 297.498 357.99 296.865C358.135 297.489 358.217 298.126 358.235 298.766C358.352 299.932 358.433 301.763 358.491 303.967C358.608 308.363 358.491 314.462 358.048 321.144C357.605 327.827 357.279 333.716 356.987 338.275C356.882 340.328 356.789 342.065 356.707 343.465C356.727 344.103 356.672 344.741 356.544 345.366V345.366Z"
              fill="#263238"
            />
            <path
              d="M413.721 344.083C412.969 344.126 412.216 344.095 411.47 343.989C410.024 343.884 407.972 343.709 405.348 343.593C400.17 343.313 393.022 343.173 385.115 343.36C377.208 343.546 370.072 344.048 364.905 344.526C362.328 344.759 360.241 345.004 358.806 345.167C358.06 345.297 357.302 345.355 356.544 345.342C357.267 345.113 358.008 344.949 358.76 344.852C360.194 344.596 362.258 344.269 364.847 343.954C370.013 343.301 377.173 342.73 385.103 342.52C391.867 342.335 398.636 342.494 405.383 342.998C407.972 343.196 410.048 343.441 411.505 343.651C412.256 343.722 412.998 343.867 413.721 344.083V344.083Z"
              fill="#263238"
            />
            <path
              d="M322.294 403.055C322.294 403.055 322.178 402.811 322.026 402.321C321.874 401.831 321.653 401.026 321.396 400.152C320.871 398.274 320.172 395.487 319.355 392.14C317.723 385.341 315.764 375.896 313.734 365.423C311.705 354.951 309.828 345.505 308.335 338.683C307.6 335.371 306.994 332.643 306.551 330.614C306.353 329.739 306.201 329.004 306.073 328.409C305.999 328.155 305.96 327.893 305.956 327.628C306.087 327.861 306.181 328.113 306.236 328.374C306.399 328.958 306.598 329.669 306.854 330.532C307.379 332.409 308.079 335.197 308.895 338.555C310.528 345.342 312.498 354.8 314.516 365.272C316.533 375.744 318.422 385.19 319.915 392.012C320.65 395.324 321.256 398.053 321.699 400.082C321.898 400.956 322.049 401.679 322.177 402.274C322.25 402.528 322.289 402.791 322.294 403.055V403.055Z"
              fill="#263238"
            />
            <path
              d="M399.902 391.37C399.651 391.463 399.388 391.522 399.121 391.545L396.858 391.919L388.544 393.178C381.547 394.251 371.856 395.849 361.162 397.609C350.469 399.37 340.766 400.886 333.722 401.808C330.224 402.274 327.344 402.624 325.373 402.822L323.04 403.044C322.778 403.084 322.51 403.084 322.247 403.044C322.501 402.958 322.763 402.899 323.029 402.869L325.279 402.496L333.594 401.236C340.591 400.163 350.294 398.577 360.987 396.805C371.681 395.032 381.383 393.528 388.427 392.607C391.926 392.14 394.794 391.79 396.777 391.592L399.109 391.382C399.371 391.336 399.639 391.332 399.902 391.37V391.37Z"
              fill="#263238"
            />
            <path
              d="M115.895 156.937C114.137 155.471 112.798 153.567 112.014 151.416C111.229 149.266 111.028 146.946 111.429 144.693C111.872 142.451 112.755 140.319 114.029 138.421C115.302 136.523 116.939 134.897 118.846 133.637C120.583 132.471 122.741 131.492 124.77 132.086C125.7 132.425 126.523 133.006 127.154 133.768C127.785 134.531 128.202 135.448 128.362 136.424C128.657 138.394 128.423 140.406 127.685 142.255C125.47 148.646 122.158 154.43 115.895 156.972"
              fill="#455A64"
            />
            <path
              d="M109.983 167.852C106.39 164.638 102.083 162.326 97.4183 161.109C92.7536 159.893 87.8661 159.805 83.161 160.855C80.8287 161.404 78.0882 162.663 77.7034 165.077C77.3186 167.491 79.4643 169.555 81.5867 170.791C86.0666 173.278 91.1823 174.384 96.2894 173.969C101.396 173.554 106.266 171.637 110.286 168.459"
              fill="#455A64"
            />
            <path
              d="M75.8026 117.311C77.7835 116.854 79.8358 116.798 81.8386 117.146C83.8414 117.495 85.7542 118.241 87.4642 119.34C90.8986 121.581 93.7715 124.581 95.8606 128.11C100.082 134.78 101.575 143.76 101.761 151.654C92.9918 151.305 85.5051 144.413 81.9366 140.669C76.2691 134.768 69.622 120.67 75.7326 117.346"
              fill="#455A64"
            />
            <path
              d="M123.242 179.782C122.444 178.724 121.712 177.618 121.05 176.47C118.78 172.021 117.121 167.287 116.117 162.395C115.128 157.439 115.507 152.307 117.213 147.55C117.746 146.126 118.455 144.774 119.324 143.526C119.619 143.05 119.984 142.622 120.408 142.255C119.395 144.019 118.521 145.859 117.796 147.76C116.317 152.443 116.036 157.423 116.98 162.243C117.997 167.07 119.562 171.765 121.644 176.237"
              fill="#263238"
            />
            <path
              d="M123.324 179.887L118.753 173.998C114.916 169.019 109.586 162.15 103.592 154.64C97.5984 147.13 92.1057 140.413 88.1175 135.561L83.4528 129.731C82.8386 129.053 82.2845 128.324 81.7969 127.55C82.4848 128.152 83.1243 128.807 83.7094 129.509C84.8755 130.815 86.5898 132.716 88.6539 135.095C92.7705 139.853 98.3563 146.523 104.35 154.033C110.344 161.544 115.581 168.482 119.301 173.567C121.166 176.109 122.647 178.231 123.65 179.619"
              fill="#263238"
            />
            <path
              d="M88.6304 166.022C89.1073 165.785 89.6192 165.628 90.1464 165.555C90.7464 165.39 91.3586 165.273 91.9772 165.205C92.7919 165.07 93.6133 164.981 94.4378 164.937C96.5902 164.801 98.7508 164.883 100.887 165.182C103.497 165.571 106.058 166.24 108.525 167.176C110.981 168.158 113.327 169.397 115.522 170.873C117.295 172.096 118.94 173.494 120.432 175.048C121.014 175.634 121.563 176.253 122.076 176.902C122.497 177.373 122.887 177.872 123.242 178.394C123.813 179.246 123.732 179.642 123.685 179.677C122.548 178.218 121.333 176.82 120.047 175.491C118.525 174.035 116.878 172.716 115.126 171.549C112.965 170.159 110.672 168.987 108.28 168.051C105.868 167.136 103.372 166.46 100.828 166.033C98.7465 165.701 96.6386 165.561 94.5311 165.613C90.8461 165.648 88.6304 166.185 88.6304 166.022Z"
              fill="#263238"
            />
            <path
              d="M284.243 463.579L149.551 462.949C141.781 462.903 134.346 459.775 128.88 454.252C123.414 448.729 120.363 441.262 120.397 433.492L121.82 95.8068C121.854 88.0313 124.973 80.5872 130.492 75.1098C136.011 69.6324 143.478 66.5697 151.254 66.5945L285.946 67.2126C293.716 67.265 301.149 70.3966 306.614 75.9208C312.079 81.4449 315.131 88.9108 315.1 96.6815L313.7 434.355C313.666 442.136 310.544 449.584 305.021 455.064C299.497 460.544 292.024 463.607 284.243 463.579V463.579Z"
              fill="#263238"
            />
            <path
              d="M285.712 78.874L259.59 78.7457C258.739 78.7609 257.899 78.9437 257.118 79.2836C256.337 79.6236 255.631 80.114 255.039 80.727C254.448 81.3399 253.983 82.0633 253.672 82.8559C253.36 83.6485 253.208 84.4947 253.223 85.3462V90.0808C253.239 90.9323 253.086 91.7785 252.774 92.5711C252.463 93.3637 251.998 94.0871 251.407 94.7C250.816 95.313 250.109 95.8034 249.328 96.1434C248.548 96.4833 247.707 96.6661 246.856 96.6813L195.953 96.4481C194.234 96.3992 192.605 95.6709 191.422 94.4226C190.24 93.1744 189.6 91.5082 189.644 89.7893V85.0547C189.688 83.3338 189.047 81.6658 187.862 80.4173C186.677 79.1687 185.044 78.4417 183.323 78.3959H174.146L151.289 78.2909C145.837 78.2692 140.6 80.4126 136.727 84.25C132.855 88.0875 130.664 93.3053 130.636 98.7571L129.249 428.349C129.227 433.798 131.369 439.032 135.204 442.902C139.04 446.773 144.254 448.962 149.703 448.99L284.126 449.608C286.825 449.62 289.499 449.101 291.997 448.08C294.494 447.058 296.766 445.555 298.683 443.656C300.6 441.756 302.124 439.498 303.168 437.01C304.212 434.522 304.755 431.852 304.767 429.154L306.155 99.55C306.175 96.8489 305.662 94.1703 304.644 91.668C303.627 89.1656 302.126 86.8887 300.226 84.9679C298.327 83.047 296.067 81.5199 293.577 80.4742C291.086 79.4285 288.414 78.8847 285.712 78.874Z"
              fill="#FAFAFA"
            />
            <path
              d="M209.679 418.005C209.765 418.573 209.674 419.154 209.42 419.669C209.166 420.184 208.76 420.609 208.257 420.886C207.753 421.163 207.177 421.28 206.606 421.22C206.035 421.16 205.496 420.926 205.061 420.55C204.627 420.175 204.318 419.675 204.176 419.118C204.034 418.561 204.067 417.975 204.269 417.437C204.471 416.899 204.833 416.436 205.306 416.11C205.779 415.784 206.34 415.611 206.915 415.614C207.597 415.572 208.268 415.799 208.784 416.246C209.301 416.693 209.622 417.324 209.679 418.005V418.005Z"
              fill="#455A64"
            />
            <path
              d="M218.273 418.052C218.357 418.62 218.264 419.2 218.008 419.714C217.752 420.227 217.345 420.65 216.841 420.926C216.338 421.201 215.762 421.316 215.191 421.254C214.62 421.193 214.082 420.958 213.649 420.581C213.216 420.205 212.908 419.704 212.768 419.148C212.627 418.591 212.66 418.005 212.863 417.468C213.066 416.931 213.428 416.469 213.901 416.144C214.374 415.819 214.935 415.646 215.509 415.65C216.193 415.608 216.866 415.836 217.383 416.285C217.9 416.735 218.219 417.369 218.273 418.052V418.052Z"
              fill="#455A64"
            />
            <path
              d="M226.868 418.087C226.954 418.655 226.863 419.236 226.609 419.751C226.355 420.266 225.949 420.691 225.446 420.968C224.943 421.245 224.367 421.362 223.795 421.302C223.224 421.242 222.685 421.008 222.25 420.632C221.816 420.257 221.507 419.757 221.365 419.2C221.224 418.643 221.256 418.056 221.458 417.519C221.66 416.981 222.022 416.518 222.495 416.192C222.968 415.866 223.53 415.693 224.104 415.696C224.787 415.651 225.459 415.877 225.977 416.324C226.494 416.772 226.814 417.405 226.868 418.087Z"
              fill="#455A64"
            />
            <path
              d="M170.869 237.763C173.061 238.078 305.187 263.99 305.187 263.99V237.763H170.869Z"
              fill="#EBEBEB"
            />
            <path
              d="M129.295 404.291C129.295 404.291 273.036 383.953 271.777 384.583C270.517 385.213 129.295 384.583 129.295 384.583V404.291Z"
              fill="#EBEBEB"
            />
            <path
              d="M296.208 19.47C296.371 19.47 296.511 26.7352 296.511 35.6913C296.511 44.6474 296.371 51.9242 296.208 51.9242C296.044 51.9242 295.905 44.659 295.905 35.6913C295.905 26.7235 296.044 19.47 296.208 19.47Z"
              fill="#263238"
            />
            <path
              d="M353.735 56.822C353.805 56.9737 347.204 60.029 339.006 63.6441C330.808 67.2592 324.103 70.0813 324.033 69.918C323.963 69.7548 330.563 66.7111 338.761 63.096C346.959 59.4809 353.665 56.6588 353.735 56.822Z"
              fill="#263238"
            />
            <path
              d="M352.277 0.169922C352.208 0.393868 352.106 0.606161 351.974 0.79965C351.706 1.24279 351.379 1.81421 350.971 2.5139C350.096 4.00659 348.802 6.14066 347.169 8.75286C343.904 14.0006 339.204 21.1142 333.875 28.8808C328.546 36.6474 323.811 43.726 320.452 48.9038C318.808 51.4343 317.467 53.5684 316.487 55.0378L315.391 56.6937C315.275 56.8969 315.134 57.0849 314.971 57.2535C315.058 57.0348 315.163 56.824 315.286 56.6237C315.555 56.1806 315.881 55.6092 316.289 54.9095C317.152 53.4168 318.458 51.2944 320.091 48.6705C323.356 43.4228 328.056 36.3092 333.373 28.5426C338.691 20.776 343.449 13.744 346.808 8.53129L350.773 2.38562L351.869 0.729678C351.989 0.532196 352.126 0.344977 352.277 0.169922V0.169922Z"
              fill="#263238"
            />
            <path
              d="M432.917 137.462H170.006C168.656 137.462 167.319 137.728 166.071 138.245C164.824 138.762 163.691 139.52 162.737 140.475C161.783 141.431 161.026 142.565 160.511 143.812C159.995 145.06 159.73 146.397 159.732 147.748V229.297C159.73 230.647 159.995 231.985 160.511 233.232C161.026 234.48 161.783 235.614 162.737 236.569C163.691 237.525 164.824 238.283 166.071 238.8C167.319 239.317 168.656 239.583 170.006 239.583H189.597C191.172 248.096 193.014 258.836 193.014 258.836L210.74 239.583H432.917C434.267 239.583 435.604 239.317 436.851 238.8C438.098 238.283 439.231 237.525 440.186 236.569C441.14 235.614 441.896 234.48 442.412 233.232C442.927 231.985 443.192 230.647 443.191 229.297V147.748C443.192 146.397 442.927 145.06 442.412 143.812C441.896 142.565 441.14 141.431 440.186 140.475C439.231 139.52 438.098 138.762 436.851 138.245C435.604 137.728 434.267 137.462 432.917 137.462V137.462Z"
              fill="#20419B"
            />
            <path
              d="M324.441 181.671C324.441 181.834 293.153 181.974 254.564 181.974C215.976 181.974 184.676 181.834 184.676 181.671C184.676 181.508 215.964 181.356 254.564 181.356C293.164 181.356 324.441 181.531 324.441 181.671Z"
              fill="#F5F5F5"
            />
            <path
              d="M324.441 169.17C324.441 169.333 293.153 169.473 254.564 169.473C215.976 169.473 184.676 169.333 184.676 169.17C184.676 169.007 215.964 168.867 254.564 168.867C293.164 168.867 324.441 168.995 324.441 169.17Z"
              fill="#F5F5F5"
            />
            <path
              d="M324.441 194.103C324.441 194.266 293.153 194.406 254.564 194.406C215.976 194.406 184.676 194.266 184.676 194.103C184.676 193.939 215.964 193.799 254.564 193.799C293.164 193.799 324.441 193.928 324.441 194.103Z"
              fill="#F5F5F5"
            />
            <path
              d="M276.22 206.942C276.22 207.105 255.73 207.245 230.448 207.245C205.166 207.245 184.676 207.105 184.676 206.942C184.676 206.779 205.166 206.639 230.448 206.639C255.73 206.639 276.22 206.767 276.22 206.942Z"
              fill="#F5F5F5"
            />
            <path
              d="M314.12 284.083H49.0634C48.1171 284.082 47.1798 284.267 46.3054 284.629C45.431 284.991 44.6367 285.522 43.9681 286.191C43.2995 286.861 42.7698 287.656 42.4094 288.531C42.0491 289.406 41.8651 290.344 41.8682 291.29V377.586C41.8682 379.494 42.6263 381.324 43.9756 382.674C45.325 384.023 47.1551 384.781 49.0634 384.781H241.573L259.066 403.825C259.066 403.825 260.896 393.119 262.447 384.781H314.085C315.993 384.781 317.823 384.023 319.173 382.674C320.522 381.324 321.28 379.494 321.28 377.586V291.29C321.283 290.347 321.101 289.412 320.742 288.539C320.384 287.667 319.858 286.873 319.193 286.204C318.528 285.535 317.738 285.003 316.867 284.639C315.997 284.275 315.063 284.086 314.12 284.083V284.083Z"
              fill="#455A64"
            />
            <path
              d="M303.73 328.607C303.73 328.771 272.442 328.911 233.842 328.911C195.242 328.911 163.954 328.771 163.954 328.607C163.954 328.444 195.242 328.304 233.842 328.304C272.442 328.304 303.73 328.397 303.73 328.607Z"
              fill="#F5F5F5"
            />
            <path
              d="M303.73 316.036C303.73 316.2 272.442 316.339 233.842 316.339C195.242 316.339 163.954 316.2 163.954 316.036C163.954 315.873 195.242 315.733 233.842 315.733C272.442 315.733 303.73 315.861 303.73 316.036Z"
              fill="#F5F5F5"
            />
            <path
              d="M303.73 340.969C303.73 341.132 272.442 341.272 233.842 341.272C195.242 341.272 163.954 341.132 163.954 340.969C163.954 340.805 195.242 340.665 233.842 340.665C272.442 340.665 303.73 340.805 303.73 340.969Z"
              fill="#F5F5F5"
            />
            <path
              d="M255.509 353.808C255.509 353.983 235.019 354.111 209.737 354.111C184.455 354.111 163.954 353.983 163.954 353.808C163.954 353.633 184.455 353.505 209.737 353.505C235.019 353.505 255.509 353.645 255.509 353.808Z"
              fill="#F5F5F5"
            />
            <path
              d="M366.818 102.874C366.981 100.728 365.069 97.7779 363.319 96.0753C362.306 95.1619 361.582 93.972 361.236 92.6522C360.89 91.3323 360.937 89.9401 361.372 88.6469C362.083 85.9997 363.763 86.198 364.427 84.6353C364.751 83.4272 364.88 82.1756 364.812 80.9269C364.951 79.8721 365.305 78.8571 365.853 77.9452C366.401 77.0334 367.131 76.2443 367.998 75.6272C368.865 75.01 369.849 74.5781 370.89 74.3583C371.931 74.1386 373.006 74.1356 374.048 74.3498C374.627 72.2803 375.815 70.4327 377.458 69.0469C379.1 67.6611 381.121 66.8011 383.259 66.5785C385.396 66.356 387.551 66.7811 389.444 67.7989C391.337 68.8166 392.88 70.3799 393.873 72.2857C395.08 71.6723 396.42 71.3657 397.774 71.393C399.128 71.4204 400.454 71.7808 401.636 72.4425C402.817 73.1042 403.818 74.0467 404.549 75.1868C405.279 76.3269 405.718 77.6295 405.826 78.9794C407.05 78.1986 408.515 77.8865 409.951 78.1008C411.387 78.3151 412.697 79.0414 413.639 80.1456C414.524 81.3007 415.049 82.691 415.149 84.1428C415.249 85.5946 414.918 87.0436 414.199 88.3087C413.46 89.5635 412.447 90.635 411.235 91.4428C410.023 92.2505 408.645 92.7738 407.202 92.9734C404.309 93.3408 401.373 92.8074 398.794 91.4457C396.226 90.0931 393.894 88.3326 391.89 86.233"
              fill="#263238"
            />
            <path
              d="M385.885 236.889C413.766 236.889 436.368 214.815 436.368 187.584C436.368 160.354 413.766 138.279 385.885 138.279C358.004 138.279 335.402 160.354 335.402 187.584C335.402 214.815 358.004 236.889 385.885 236.889Z"
              fill="#E0E0E0"
            />
            <path
              d="M436.578 187.619L430.992 173.299L410.969 185.881L428.811 213.391C428.811 213.391 436.065 203.969 436.578 187.619Z"
              fill="#EB996E"
            />
            <path
              d="M381.337 156.599C384.786 156.619 388.111 155.312 390.623 152.948C393.134 150.584 394.641 147.344 394.829 143.9C395.121 137.964 395.377 132.238 395.377 132.238C395.377 132.238 406.386 131.445 407.4 120.67C408.415 109.894 408.566 84.9968 408.566 84.9968C402.594 81.8619 395.897 80.3647 389.158 80.6576C382.419 80.9505 375.878 83.0231 370.2 86.6644L369.15 87.3291L367.879 143.072C367.867 144.846 368.206 146.605 368.877 148.247C369.548 149.889 370.538 151.382 371.789 152.64C373.04 153.897 374.528 154.895 376.167 155.574C377.805 156.254 379.563 156.602 381.337 156.599V156.599Z"
              fill="#FFBE9D"
            />
            <path
              d="M387.611 101.58C387.901 101.682 388.154 101.867 388.339 102.113C388.524 102.358 388.632 102.653 388.651 102.96C388.669 103.267 388.597 103.572 388.443 103.838C388.288 104.104 388.059 104.318 387.783 104.454C387.508 104.591 387.198 104.642 386.893 104.604C386.588 104.565 386.302 104.437 386.069 104.236C385.837 104.035 385.668 103.769 385.586 103.473C385.503 103.177 385.51 102.863 385.605 102.571C385.74 102.174 386.027 101.847 386.403 101.661C386.779 101.476 387.213 101.446 387.611 101.58V101.58Z"
              fill="#263238"
            />
            <path
              d="M390.666 102.139C390.479 102.314 389.5 101.428 387.96 101.335C386.421 101.242 385.325 101.941 385.173 101.743C385.022 101.545 385.29 101.323 385.78 100.997C386.45 100.573 387.24 100.377 388.03 100.437C388.818 100.49 389.566 100.797 390.164 101.311C390.573 101.708 390.724 102.058 390.666 102.139Z"
              fill="#263238"
            />
            <path
              d="M404.053 101.58C404.343 101.682 404.597 101.867 404.782 102.113C404.967 102.358 405.075 102.653 405.094 102.96C405.112 103.267 405.04 103.572 404.885 103.838C404.731 104.104 404.502 104.318 404.226 104.454C403.951 104.591 403.641 104.642 403.336 104.604C403.031 104.565 402.745 104.437 402.512 104.236C402.279 104.035 402.111 103.769 402.029 103.473C401.946 103.177 401.953 102.863 402.048 102.571C402.183 102.174 402.47 101.847 402.846 101.661C403.222 101.476 403.656 101.446 404.053 101.58V101.58Z"
              fill="#263238"
            />
            <path
              d="M407.272 102.163C407.097 102.349 406.106 101.463 404.567 101.37C403.027 101.276 401.931 101.976 401.78 101.778C401.628 101.58 401.896 101.358 402.398 101.032C403.063 100.607 403.849 100.41 404.637 100.472C405.427 100.527 406.178 100.834 406.782 101.346C407.226 101.743 407.365 102.093 407.272 102.163Z"
              fill="#263238"
            />
            <path
              d="M395.96 113.813C396.756 113.567 397.589 113.46 398.421 113.498C398.817 113.498 399.132 113.428 399.179 113.218C399.234 112.819 399.169 112.413 398.992 112.052C398.689 111.037 398.374 110.011 398.048 108.938C396.765 104.507 395.914 100.868 396.135 100.775C396.357 100.682 397.593 104.273 398.876 108.682C399.179 109.754 399.459 110.792 399.75 111.807C399.962 112.326 399.991 112.902 399.832 113.439C399.762 113.585 399.659 113.712 399.531 113.811C399.404 113.91 399.255 113.979 399.097 114.011C398.873 114.039 398.646 114.039 398.421 114.011C397.595 114.085 396.763 114.018 395.96 113.813Z"
              fill="#263238"
            />
            <path
              d="M395.331 132.098C390.307 131.787 385.487 129.998 381.477 126.955C381.477 126.955 384.194 134.99 395.132 134.897L395.331 132.098Z"
              fill="#EB996E"
            />
            <path
              d="M368.824 103.165C368.834 102.742 368.679 102.332 368.391 102.02C368.104 101.709 367.707 101.522 367.285 101.498C365.255 101.405 361.92 102.139 361.454 107.69C360.847 115.62 368.591 113.789 368.626 113.521C368.661 113.253 368.789 106.256 368.824 103.165Z"
              fill="#FFBE9D"
            />
            <path
              d="M366.97 110.407C366.97 110.407 366.818 110.501 366.573 110.582C366.409 110.651 366.232 110.686 366.054 110.686C365.876 110.686 365.7 110.651 365.535 110.582C365.043 110.22 364.657 109.733 364.417 109.171C364.177 108.609 364.092 107.993 364.171 107.387C364.2 106.706 364.384 106.04 364.707 105.44C364.807 105.194 364.966 104.976 365.169 104.805C365.372 104.635 365.614 104.516 365.873 104.46C366.037 104.427 366.206 104.454 366.352 104.535C366.497 104.617 366.609 104.747 366.666 104.903C366.76 105.125 366.666 105.288 366.736 105.3C366.806 105.311 366.923 105.183 366.876 104.845C366.848 104.646 366.748 104.464 366.596 104.332C366.392 104.149 366.124 104.053 365.85 104.064C365.513 104.106 365.192 104.237 364.92 104.441C364.648 104.646 364.434 104.917 364.299 105.23C363.899 105.885 363.674 106.632 363.646 107.399C363.555 108.104 363.675 108.82 363.991 109.457C364.306 110.094 364.803 110.623 365.419 110.979C365.63 111.055 365.855 111.084 366.079 111.064C366.302 111.044 366.519 110.975 366.713 110.862C366.935 110.606 366.993 110.431 366.97 110.407Z"
              fill="#EB996E"
            />
            <path
              d="M383.529 97.9062C383.925 98.4193 385.302 98.1861 387.097 98.1045C388.893 98.0228 390.281 98.0345 390.596 97.4747C390.911 96.915 389.243 95.5739 386.934 95.7721C384.625 95.9704 383.086 97.3814 383.529 97.9062Z"
              fill="#263238"
            />
            <path
              d="M400.998 97.3814C401.208 98.0694 402.526 98.2793 403.984 98.2676C405.441 98.256 406.748 97.9994 406.946 97.2997C407.144 96.6 405.78 95.6554 403.949 95.6788C402.118 95.7021 400.777 96.705 400.998 97.3814Z"
              fill="#263238"
            />
            <path
              d="M410.048 88.3786C410.048 88.3786 403.505 91.5505 395.004 87.3057C389.418 84.5186 382.923 83.1542 381.488 84.2737C380.054 85.3932 379.751 91.154 374.223 92.8333C374.223 92.8333 374.596 103.795 370.223 103.41C365.85 103.026 368.579 86.0462 368.579 86.0462L379.284 80.8218L390.946 78.6178L401.943 80.1688L410.456 82.5011L410.048 88.3786Z"
              fill="#263238"
            />
            <path
              d="M336.801 151.911C348.241 140.145 368.369 141.416 368.369 141.416L385.057 153.369L394.514 142.349C401.22 143.262 407.729 145.275 413.779 148.308L414.421 148.506V148.634C421.744 152.611 432.1 162.15 441.977 171.398L430.164 182.36L417.08 194.021C417.109 194.096 417.109 194.18 417.08 194.254C416.951 194.966 418.899 210.931 420.905 223.059C415.21 228.675 408.233 232.82 400.577 235.134C392.921 237.449 384.816 237.863 376.964 236.341C371.611 235.397 369.022 234.102 364.066 232.843C360.495 231.896 357.303 229.871 354.925 227.043C352.548 224.215 351.102 220.722 350.783 217.041V217.041C349.897 206.872 349.221 194.278 349.221 194.278"
              fill="#455A64"
            />
            <path
              d="M404.975 144.413C405.267 145.018 405.509 145.646 405.698 146.29C406.129 147.457 406.701 149.287 407.389 151.491C408.765 155.9 410.526 161.987 412.182 168.902C413.838 175.818 415.085 182.033 415.867 186.581C416.263 188.914 416.555 190.709 416.73 191.992C416.854 192.65 416.929 193.317 416.951 193.986C416.745 193.347 416.593 192.692 416.496 192.027C416.228 190.663 415.878 188.855 415.447 186.663C414.549 182.138 413.243 175.899 411.587 169.042C409.931 162.185 408.252 156.016 406.992 151.62L405.476 146.36C405.264 145.724 405.096 145.073 404.975 144.413V144.413Z"
              fill="#263238"
            />
            <path
              d="M415.715 186.453C415.61 186.324 420.601 182.08 426.864 176.984C433.126 171.887 438.28 167.852 438.385 167.981C438.49 168.109 433.511 172.354 427.249 177.45C420.986 182.546 415.832 186.581 415.715 186.453Z"
              fill="#263238"
            />
            <path
              opacity="0.2"
              d="M351.903 168.051L341.687 201.718L345.5 203.7L349.256 194.185C349.256 194.185 353.081 174.173 351.903 168.051Z"
              fill="black"
            />
            <g opacity="0.2">
              <path
                d="M403.867 144.436C409.383 147.34 395.552 154.337 389.313 154.15L393.85 142.185C393.85 142.185 402.013 143.456 403.867 144.436Z"
                fill="black"
              />
            </g>
            <path
              d="M366.725 142.185V133.264C366.725 133.264 384.135 137.754 384.765 148.168C384.765 148.168 386.981 136.646 395.96 136.098L397.231 152.296L386.736 161.392H376.205L367.821 154.15L366.725 142.908"
              fill="#455A64"
            />
            <path
              d="M388.695 198.149C388.635 197.513 388.635 196.873 388.695 196.237C388.695 195.001 388.695 193.216 388.754 191.012C388.754 186.616 388.625 180.517 388.077 173.835C387.529 167.153 386.643 161.112 385.908 156.774C385.535 154.686 385.22 152.961 384.987 151.643C384.836 151.019 384.742 150.383 384.707 149.742C384.926 150.346 385.097 150.966 385.22 151.596C385.523 152.762 385.908 154.535 386.328 156.704C387.407 162.353 388.186 168.056 388.66 173.788C389.132 179.521 389.295 185.274 389.15 191.024C389.092 193.228 389.01 195.012 388.905 196.248C388.88 196.886 388.81 197.521 388.695 198.149V198.149Z"
              fill="#263238"
            />
            <path
              d="M383.972 145.171C383.624 145.112 383.267 145.144 382.934 145.264C382.08 145.746 381.451 146.547 381.185 147.491C379.747 150.601 378.626 153.848 377.838 157.182L377.745 157.579L377.395 157.369C376.357 156.739 375.284 156.063 374.212 155.34C372.257 154.009 370.373 152.576 368.567 151.048C367.746 150.363 367.089 149.502 366.643 148.529C366.293 147.672 366.095 146.761 366.06 145.835C366.025 144.526 366.154 143.218 366.445 141.94C366.515 141.464 366.644 140.997 366.83 140.553C366.815 141.03 366.764 141.506 366.678 141.975C366.48 143.244 366.418 144.53 366.492 145.812C366.547 146.678 366.756 147.527 367.11 148.319C367.537 149.225 368.163 150.022 368.94 150.652C370.585 152.028 372.497 153.45 374.55 154.85C375.623 155.573 376.672 156.261 377.71 156.891L377.267 157.077C378.046 153.728 379.221 150.482 380.765 147.41C381.003 146.871 381.308 146.366 381.675 145.905C381.962 145.55 382.347 145.286 382.783 145.147C383.669 144.868 383.996 145.159 383.972 145.171Z"
              fill="#263238"
            />
            <path
              d="M395.96 136.098C396.066 136.553 396.121 137.018 396.124 137.486L396.404 141.287C396.532 142.885 396.73 144.786 397.057 146.873C397.337 147.993 397.408 149.156 397.267 150.302C396.825 151.503 396.007 152.53 394.934 153.229C393.571 154.075 392.291 155.047 391.109 156.133L390.643 156.657V155.958C390.431 152.911 389.551 149.949 388.066 147.281C386.958 145.287 386.048 144.273 386.118 144.214C386.188 144.156 386.398 144.424 386.806 144.902C387.392 145.59 387.922 146.323 388.392 147.095C390.014 149.775 390.984 152.799 391.226 155.923L390.713 155.748C391.911 154.617 393.22 153.609 394.619 152.739C395.567 152.117 396.294 151.21 396.695 150.15C396.817 149.082 396.754 148.001 396.509 146.955C396.217 144.844 396.065 142.92 395.984 141.311C395.902 139.701 395.89 138.395 395.902 137.497C395.862 137.03 395.882 136.56 395.96 136.098V136.098Z"
              fill="#263238"
            />
            <path
              d="M452.018 138.337C451.306 139.165 425.651 173.509 425.651 173.509L429.266 175.561L455.574 141.742L452.018 138.337Z"
              fill="#263238"
            />
            <path
              d="M349.011 165.439C349.011 165.439 375.984 204.272 377.617 205.03C380.812 206.534 417.709 174.651 421.231 171.596C421.721 170.943 422.724 169.578 424.73 166.861C424.73 166.861 427.645 153.544 430.747 154.558C433.849 155.573 429.044 161.334 431.073 166.395C433.103 171.456 442.362 159.398 442.735 158.302L445.126 155.176C445.126 155.176 447.633 162.278 447.458 165.252C446.91 174.756 437.919 186.056 437.919 186.056C437.919 186.056 397.535 236.085 369.873 237.636C361.908 238.079 347.716 215.479 338.282 198.523C335.527 193.571 334.678 187.783 335.896 182.25C337.114 176.716 340.315 171.819 344.894 168.482L349.011 165.439Z"
              fill="#FFBE9D"
            />
            <path
              d="M442.875 166.931C443.392 165.737 443.968 164.57 444.601 163.433C445.121 162.236 445.705 161.068 446.35 159.934C445.615 162.459 444.43 164.829 442.852 166.931H442.875Z"
              fill="#EB996E"
            />
            <path
              d="M344.451 146.489C344.451 146.489 315.495 156.786 337.023 205.473L370.445 185.298L365.687 173.917L344.451 146.489Z"
              fill="#455A64"
            />
            <path
              d="M342.235 147.34C343.41 147.231 344.594 147.329 345.734 147.631C347.068 147.977 348.345 148.515 349.524 149.229C351.082 150.2 352.502 151.376 353.745 152.727C356.903 156.254 359.549 160.207 361.605 164.471C364.054 169.205 366.34 174.605 368.684 180.26C369.384 181.963 370.083 183.631 370.736 185.217L370.841 185.473L370.596 185.601C360.929 190.873 352.509 195.805 346.562 199.502C343.576 201.345 341.209 202.861 339.565 203.91L337.699 205.076C337.485 205.225 337.259 205.353 337.023 205.461C337.208 205.282 337.411 205.121 337.629 204.983C338.037 204.68 338.644 204.237 339.448 203.689C341.034 202.522 343.378 201.006 346.329 199.117C352.229 195.327 360.626 190.313 370.305 185.03L370.165 185.403C369.512 183.817 368.812 182.15 368.112 180.447C365.78 174.791 363.448 169.404 361.115 164.681C359.098 160.463 356.517 156.54 353.442 153.019C352.24 151.68 350.868 150.504 349.361 149.52C348.207 148.797 346.959 148.235 345.652 147.853C344.53 147.585 343.387 147.414 342.235 147.34V147.34Z"
              fill="#263238"
            />
            <path
              d="M404.508 75.1544C404.582 75.3169 404.629 75.4901 404.648 75.6675C404.737 76.1638 404.768 76.6685 404.742 77.1719C404.63 79.0656 403.844 80.8569 402.526 82.2213C401.525 83.2203 400.273 83.9306 398.902 84.2775C397.531 84.6243 396.092 84.5949 394.736 84.1922C393.094 83.7555 391.673 82.7249 390.748 81.3001C390.258 80.5139 390.048 79.585 390.153 78.6646C390.206 78.1848 390.361 77.722 390.609 77.3075C390.856 76.8931 391.19 76.5366 391.587 76.2623C392.002 76.0013 392.474 75.8477 392.963 75.8151C393.452 75.7825 393.941 75.8721 394.386 76.0757C395.251 76.4424 395.937 77.1331 396.299 77.9998C396.586 78.8083 396.666 79.676 396.532 80.5235C396.397 81.3711 396.053 82.1713 395.529 82.8511C394.653 83.9844 393.406 84.7721 392.006 85.0751C390.606 85.378 389.145 85.1769 387.879 84.507C386.965 84.0288 385.999 83.6608 384.998 83.4108C384.25 83.2424 383.468 83.3244 382.771 83.6441C382.187 83.9591 381.633 84.326 381.115 84.7403C381.115 84.7403 381.22 84.5887 381.453 84.3321C381.597 84.1718 381.757 84.0271 381.931 83.9006C382.16 83.711 382.41 83.5504 382.678 83.4225C383.399 83.0624 384.215 82.9399 385.01 83.0726C386.049 83.297 387.055 83.6496 388.007 84.1222C388.547 84.3604 389.11 84.544 389.686 84.6703C390.307 84.7863 390.943 84.7863 391.564 84.6703C392.96 84.4486 394.215 83.6914 395.062 82.5595C395.523 81.9569 395.826 81.2489 395.945 80.4995C396.063 79.7502 395.992 78.9832 395.739 78.2681C395.6 77.9065 395.386 77.5782 395.112 77.3044C394.838 77.0307 394.509 76.8176 394.148 76.6791C393.786 76.5405 393.399 76.4796 393.012 76.5002C392.625 76.5208 392.247 76.6225 391.902 76.7987C391.58 77.0293 391.31 77.3248 391.109 77.6663C390.908 78.0078 390.781 78.3876 390.736 78.7812C390.653 79.5675 390.833 80.3592 391.249 81.0319C392.095 82.3343 393.39 83.2806 394.887 83.6907C396.152 84.0784 397.498 84.1217 398.785 83.8163C400.072 83.5109 401.255 82.8677 402.211 81.9531C403.493 80.6758 404.297 78.9955 404.485 77.1952C404.535 76.5161 404.542 75.8345 404.508 75.1544V75.1544Z"
              fill="#455A64"
            />
            <path
              d="M398.199 119.13C398.199 119.2 397.85 119.364 397.208 119.387C396.368 119.398 395.537 119.207 394.786 118.83C394.035 118.452 393.386 117.899 392.893 117.218C392.544 116.681 392.439 116.297 392.508 116.262C393.276 117.037 394.133 117.718 395.062 118.291C396.071 118.696 397.124 118.978 398.199 119.13V119.13Z"
              fill="#263238"
            />
            <path
              d="M367.623 114.034C367.681 114.034 368.007 114.547 367.623 115.352C367.501 115.579 367.335 115.78 367.135 115.942C366.934 116.104 366.704 116.225 366.456 116.297C366.139 116.368 365.809 116.361 365.494 116.278C365.18 116.195 364.89 116.037 364.649 115.819C364.386 115.604 364.176 115.331 364.036 115.021C363.897 114.712 363.831 114.374 363.844 114.034C363.864 113.522 364.053 113.03 364.381 112.635C364.94 111.982 365.547 111.912 365.547 111.982C365.547 112.052 365.127 112.273 364.754 112.891C364.515 113.25 364.412 113.682 364.463 114.109C364.515 114.537 364.717 114.932 365.034 115.224C365.205 115.38 365.409 115.495 365.631 115.562C365.853 115.628 366.087 115.644 366.316 115.609C366.701 115.522 367.041 115.298 367.273 114.979C367.646 114.501 367.529 114.034 367.623 114.034Z"
              fill="#263238"
            />
            <path
              d="M406.432 79.0494C406.816 79.6981 407.01 80.4416 406.992 81.1952C407.084 83.0369 406.461 84.8429 405.253 86.2359C404.044 87.6289 402.345 88.5011 400.508 88.6703C399.764 88.7976 398.999 88.7085 398.304 88.4137C398.304 88.3088 399.156 88.4137 400.45 88.2388C402.13 87.9805 403.665 87.1366 404.783 85.8562C405.901 84.5759 406.531 82.9415 406.561 81.2418C406.596 79.9124 406.328 79.1077 406.432 79.0494Z"
              fill="#455A64"
            />
            <path
              d="M366.993 176.657C367.063 176.809 359.378 180.762 349.815 185.485C340.253 190.208 332.451 193.928 332.323 193.776C332.195 193.625 339.926 189.671 349.489 184.937C359.051 180.202 366.923 176.505 366.993 176.657Z"
              fill="#E0E0E0"
            />
            <path
              d="M359.984 163.433C360.066 163.573 353.22 167.409 344.696 171.981C336.171 176.552 329.198 180.144 329.128 179.992C329.058 179.841 335.891 176.016 344.416 171.444C352.941 166.873 359.903 163.281 359.984 163.433Z"
              fill="#E0E0E0"
            />
            <path
              d="M353.209 152.739C352.12 153.493 350.978 154.168 349.792 154.756L341.419 159.421L333.093 164.179C331.973 164.892 330.804 165.523 329.594 166.068C330.624 165.226 331.725 164.473 332.883 163.818C334.959 162.5 337.862 160.751 341.128 158.896C344.393 157.042 347.402 155.479 349.606 154.383C350.757 153.733 351.963 153.183 353.209 152.739V152.739Z"
              fill="#E0E0E0"
            />
            <path
              d="M378.829 150.185C378.04 150.148 377.269 149.942 376.567 149.579C374.858 148.813 373.283 147.777 371.902 146.512C370.518 145.248 369.246 143.867 368.101 142.384C367.577 141.812 367.134 141.173 366.783 140.483C366.853 140.483 367.459 141.054 368.427 142.115C369.395 143.177 370.701 144.623 372.311 146.057C373.666 147.288 375.175 148.338 376.8 149.182C378.001 149.847 378.853 150.115 378.829 150.185Z"
              fill="#E0E0E0"
            />
            <path
              d="M395.832 143.888C395.447 145.294 394.596 146.527 393.418 147.387C392.374 148.406 391.011 149.036 389.558 149.171C390.767 148.489 391.935 147.737 393.057 146.92C394.032 145.956 394.958 144.944 395.832 143.888V143.888Z"
              fill="#E0E0E0"
            />
            <path
              d="M426.164 161.439C425.952 161.504 425.733 161.543 425.511 161.555L423.633 161.789C422.001 161.975 419.634 162.197 416.718 162.43C410.887 162.896 402.724 163.351 393.826 163.678C384.929 164.004 376.777 164.109 370.9 164.063C367.973 164.063 365.594 163.981 363.961 163.923L362.072 163.829C361.852 163.83 361.632 163.802 361.419 163.748C361.636 163.72 361.855 163.72 362.072 163.748H363.961L370.9 163.689C376.73 163.631 384.894 163.444 393.803 163.13C402.713 162.815 410.829 162.407 416.683 162.057L423.61 161.614L425.499 161.497C425.717 161.447 425.941 161.428 426.164 161.439V161.439Z"
              fill="#E0E0E0"
            />
            <path
              d="M416.951 175.269C416.321 175.413 415.682 175.515 415.039 175.573C413.791 175.724 411.995 175.911 409.779 176.121C405.336 176.529 399.191 176.949 392.392 177.229C385.593 177.509 379.424 177.567 374.899 177.52C372.672 177.52 370.865 177.45 369.617 177.404C368.968 177.409 368.321 177.358 367.681 177.252C368.323 177.175 368.97 177.144 369.617 177.159L374.899 177.089C379.354 177.089 385.511 176.89 392.299 176.622C399.086 176.354 405.243 175.993 409.686 175.689L414.957 175.328C415.618 175.246 416.286 175.226 416.951 175.269V175.269Z"
              fill="#E0E0E0"
            />
            <path
              d="M396.042 192.319C394.773 192.612 393.482 192.795 392.182 192.867C389.78 193.112 386.468 193.38 382.794 193.566C379.121 193.753 375.797 193.811 373.383 193.8C372.083 193.861 370.78 193.81 369.489 193.648C370.773 193.452 372.072 193.358 373.372 193.368L382.759 192.96L392.089 192.435C393.402 192.306 394.723 192.267 396.042 192.319V192.319Z"
              fill="#E0E0E0"
            />
            <path
              d="M369.325 151.853C369.325 152.028 365.745 152.063 361.337 152.249C356.929 152.436 353.372 152.681 353.349 152.506C358.621 151.574 363.994 151.354 369.325 151.853V151.853Z"
              fill="#E0E0E0"
            />
            <path
              d="M418.514 151.223C415.07 151.507 411.612 151.592 408.158 151.48C404.699 151.571 401.237 151.458 397.791 151.142C401.239 150.851 404.7 150.761 408.158 150.873C411.614 150.782 415.072 150.898 418.514 151.223V151.223Z"
              fill="#E0E0E0"
            />
            <path
              d="M420.31 222.184C419.982 222.428 419.625 222.632 419.249 222.791C418.549 223.152 417.511 223.63 416.217 224.178C412.815 225.579 409.305 226.698 405.721 227.525C402.139 228.342 398.496 228.869 394.829 229.099C393.43 229.181 392.287 229.204 391.494 229.181C391.103 229.199 390.712 229.172 390.328 229.099C390.328 229.006 392.054 228.936 394.841 228.668C398.47 228.319 402.073 227.739 405.628 226.93C409.193 226.112 412.699 225.056 416.123 223.77C418.654 222.791 420.275 222.091 420.31 222.184Z"
              fill="#E0E0E0"
            />
            <path
              d="M105.295 380.91C133.176 380.91 155.778 359.347 155.778 332.747C155.778 306.148 133.176 284.585 105.295 284.585C77.4142 284.585 54.8121 306.148 54.8121 332.747C54.8121 359.347 77.4142 380.91 105.295 380.91Z"
              fill="#E0E0E0"
            />
            <path
              d="M95.0099 216.656C87.01 217.076 83.8614 225.426 82.9051 227.292C82.0838 229.225 81.6481 231.301 81.6223 233.402C81.5921 235.505 81.0646 237.571 80.083 239.431C78.3221 242.242 74.6137 243.699 73.6108 246.86C72.4447 250.44 75.395 254.265 74.6137 257.938C74.1939 259.909 72.7595 261.53 72.2814 263.477C71.8402 265.559 72.2294 267.731 73.3659 269.53C75.9898 274.055 81.4474 276.317 86.6718 276.527C91.8962 276.737 97.004 275.151 102.007 273.611"
              fill="#263238"
            />
            <path
              d="M130.251 227.012C129.944 227.896 129.89 228.848 130.097 229.761C130.303 230.674 130.76 231.511 131.417 232.178C132.761 233.466 134.212 234.636 135.755 235.676C136.526 236.208 137.185 236.886 137.693 237.672C138.202 238.459 138.55 239.338 138.717 240.259C138.869 241.962 138.006 243.664 138.239 245.355C138.846 249.729 145.808 251.035 146.169 255.419C146.426 258.346 143.417 261.04 144.257 263.851C144.711 264.905 145.299 265.897 146.006 266.801C147.988 270.09 146.647 274.614 143.779 277.157C140.91 279.699 136.945 280.597 133.12 280.772C129.901 280.912 126.24 280.387 124.35 277.786C122.613 275.396 122.974 272.154 123.312 269.227C124.933 255.559 124.654 241.752 124.385 227.991"
              fill="#263238"
            />
            <path
              d="M97.1206 290.253C97.1789 283.069 97.1206 275.512 97.1206 275.512C97.1206 275.512 87.8962 273.868 86.112 261.787C84.4211 250.253 87.2782 225.461 87.2782 225.461C100.654 220.178 115.721 222.219 125.085 232.982L123.616 291.29C123.452 298.054 117.493 303.302 110.252 303.069C102.94 302.824 97.0622 297.121 97.1206 290.253Z"
              fill="#FFBE9D"
            />
            <path
              d="M89.797 244.493C89.7873 244.896 89.9354 245.288 90.2098 245.584C90.4841 245.88 90.8632 246.057 91.2663 246.078C91.4634 246.096 91.662 246.074 91.8505 246.014C92.0391 245.954 92.2139 245.858 92.3647 245.73C92.5156 245.602 92.6394 245.445 92.7291 245.269C92.8188 245.092 92.8726 244.9 92.8873 244.702C92.8939 244.298 92.7432 243.906 92.4669 243.61C92.1907 243.315 91.8105 243.138 91.4063 243.116C91.2098 243.099 91.0118 243.121 90.8239 243.181C90.6361 243.241 90.4621 243.338 90.3122 243.466C90.1623 243.594 90.0395 243.751 89.951 243.927C89.8625 244.104 89.8102 244.296 89.797 244.493V244.493Z"
              fill="#263238"
            />
            <path
              d="M87.313 243.303C87.4996 243.501 88.6774 242.65 90.3451 242.673C93.482 243.128 93.4237 243.606 93.4004 243.338C93.4937 243.256 93.3071 242.883 92.7707 242.498C92.0608 241.986 91.2086 241.709 90.3334 241.705C89.4653 241.697 88.6165 241.962 87.9078 242.463C87.3947 242.848 87.2198 243.21 87.313 243.303Z"
              fill="#263238"
            />
            <path
              d="M106.846 244.994C106.837 245.396 106.985 245.786 107.26 246.081C107.534 246.375 107.913 246.55 108.316 246.568C108.512 246.587 108.711 246.567 108.899 246.507C109.088 246.448 109.263 246.351 109.413 246.223C109.563 246.094 109.686 245.937 109.774 245.76C109.862 245.582 109.913 245.389 109.925 245.192C109.934 244.789 109.786 244.397 109.512 244.101C109.238 243.805 108.859 243.627 108.455 243.606C108.258 243.59 108.06 243.614 107.872 243.675C107.684 243.736 107.51 243.834 107.361 243.964C107.211 244.093 107.088 244.25 107 244.427C106.912 244.604 106.859 244.797 106.846 244.994V244.994Z"
              fill="#263238"
            />
            <path
              d="M104.677 243.874C104.864 244.084 106.053 243.221 107.709 243.245C109.365 243.268 110.59 244.119 110.776 243.921C110.963 243.723 110.671 243.466 110.146 243.07C109.432 242.559 108.575 242.286 107.697 242.288C106.83 242.273 105.98 242.534 105.272 243.035C104.759 243.478 104.584 243.781 104.677 243.874Z"
              fill="#263238"
            />
            <path
              d="M98.4847 255.828C97.6149 255.531 96.7086 255.354 95.7909 255.303C95.3711 255.303 94.9629 255.163 94.8929 254.871C94.8684 254.431 94.9738 253.993 95.1962 253.612L96.4906 250.393C97.6832 247.659 98.6618 244.836 99.4177 241.95C99.2311 241.88 97.6218 245.554 95.8142 250.113C95.3827 251.279 94.9746 252.329 94.5781 253.355C94.2998 253.864 94.2129 254.456 94.3332 255.023C94.3919 255.176 94.4859 255.312 94.6075 255.422C94.7291 255.531 94.8749 255.61 95.0329 255.653C95.2632 255.724 95.5031 255.76 95.7443 255.758C96.6512 255.896 97.5719 255.92 98.4847 255.828Z"
              fill="#263238"
            />
            <path
              d="M97.1086 275.512C102.789 275.666 108.4 274.241 113.318 271.396C113.318 271.396 109.307 279.641 97.2369 278.393L97.1086 275.512Z"
              fill="#EB996E"
            />
            <path
              d="M99.1262 259.804C99.449 259.41 99.8664 259.104 100.339 258.914C100.813 258.724 101.326 258.658 101.832 258.72C102.216 258.753 102.589 258.867 102.927 259.054C103.264 259.241 103.559 259.496 103.791 259.804C104.01 260.086 104.137 260.428 104.156 260.785C104.175 261.141 104.084 261.495 103.896 261.798C103.622 262.091 103.269 262.298 102.879 262.391C102.489 262.484 102.08 262.461 101.703 262.323C100.921 262.036 100.204 261.596 99.5926 261.029C99.4081 260.891 99.25 260.721 99.1262 260.527C99.064 260.436 99.0308 260.328 99.0308 260.218C99.0308 260.108 99.064 260 99.1262 259.909"
              fill="#EB996E"
            />
            <path
              d="M103.383 257.029C103.103 257.029 103.079 258.825 101.517 260.084C99.9541 261.343 98.0183 261.134 98.0183 261.378C98.0183 261.623 98.4498 261.74 99.2544 261.763C100.316 261.8 101.356 261.452 102.181 260.784C102.971 260.149 103.485 259.234 103.616 258.23C103.698 257.472 103.499 257.017 103.383 257.029Z"
              fill="#263238"
            />
            <path
              d="M119.837 224.376C115.429 230.673 103.732 237.146 95.3477 238.65C96.3156 237.367 101.458 234.195 102.438 232.912C98.648 235.735 93.2137 239.536 88.1875 239.781C87.5945 239.868 86.9891 239.775 86.4499 239.513C86.1678 239.287 85.9419 238.999 85.7901 238.671C85.6384 238.344 85.5648 237.985 85.5753 237.624C85.2893 233.976 85.4066 230.307 85.9252 226.685C86.3241 223.624 87.8807 220.832 90.2749 218.884C93.5052 216.388 98.1115 215.782 102.461 215.677C106.102 215.44 109.753 215.932 113.202 217.123C116.537 218.429 119.394 221.029 119.884 224.12"
              fill="#263238"
            />
            <path
              d="M132.502 252.586C133.054 249.922 133.444 247.227 133.668 244.516C134.03 238.289 132.234 215.362 110.765 217.158L109.89 223.7C109.89 223.7 106.578 246.079 120.467 246.242C121.401 251.239 123.842 255.829 127.464 259.396C127.464 259.396 131.102 259.291 132.409 252.586"
              fill="#263238"
            />
            <path
              d="M120.502 246.242C121.799 245.987 123.141 246.087 124.385 246.533C125.631 247.003 126.642 247.946 127.196 249.157C127.68 250.394 127.68 251.768 127.196 253.005C126.696 254.23 125.932 255.33 124.957 256.224C124.314 256.914 123.514 257.439 122.624 257.752C122.176 257.906 121.695 257.947 121.227 257.87C120.759 257.792 120.317 257.599 119.942 257.308"
              fill="#FFBE9D"
            />
            <path
              d="M121.12 254.078C120.969 254.078 121.528 254.906 122.706 254.603C123.023 254.503 123.317 254.339 123.569 254.123C123.822 253.907 124.028 253.642 124.176 253.344C124.541 252.688 124.771 251.967 124.852 251.221C124.964 250.406 124.777 249.578 124.327 248.889C124.145 248.601 123.899 248.359 123.607 248.183C123.316 248.007 122.987 247.901 122.648 247.874C121.482 247.874 121.05 248.667 121.178 248.691C121.307 248.714 121.75 248.364 122.543 248.562C123.028 248.751 123.429 249.109 123.671 249.569C123.913 250.03 123.98 250.563 123.861 251.07C123.709 252.399 123.184 253.612 122.473 253.903C121.762 254.195 121.202 253.973 121.12 254.078Z"
              fill="#EB996E"
            />
            <path
              d="M122.846 222.021C124.51 222.965 126.478 223.221 128.329 222.732C130.179 222.244 131.765 221.051 132.747 219.408C133.426 218.378 133.669 217.12 133.423 215.91C133.148 215.12 132.67 214.415 132.038 213.867C131.406 213.319 130.641 212.946 129.82 212.785C128.646 212.478 127.415 212.466 126.235 212.749C125.055 213.031 123.963 213.6 123.056 214.406C122.168 215.237 121.533 216.301 121.224 217.477C120.915 218.653 120.943 219.892 121.307 221.053C121.719 222.193 122.476 223.176 123.473 223.866C124.47 224.556 125.657 224.917 126.869 224.901"
              fill="#263238"
            />
            <path
              d="M124.782 256.819C123.553 257.635 122.432 258.602 121.446 259.699C120.46 260.815 119.945 262.27 120.012 263.758C120.193 264.561 120.283 265.383 120.28 266.206C119.926 267.285 119.35 268.278 118.589 269.122C117.885 270.413 117.61 271.894 117.805 273.352C118 274.809 118.655 276.167 119.674 277.227C121.791 279.308 124.647 280.465 127.615 280.445C129.323 280.591 131.033 280.182 132.49 279.279C134.496 277.88 135.265 275.244 135.324 272.784"
              fill="#263238"
            />
            <path
              d="M126.251 226.837C125.954 227.632 125.529 228.372 124.992 229.029C123.806 230.704 122.415 232.224 120.852 233.554C119.3 234.898 117.588 236.045 115.756 236.971C115.03 237.396 114.243 237.707 113.423 237.892C115.901 236.482 118.264 234.88 120.49 233.099C122.571 231.165 124.497 229.071 126.251 226.837V226.837Z"
              fill="#455A64"
            />
            <path
              d="M128.479 226.709C128.512 227.706 128.382 228.703 128.094 229.659C127.519 231.994 126.599 234.23 125.365 236.294C124.123 238.353 122.578 240.212 120.782 241.81C120.071 242.512 119.252 243.095 118.356 243.536C120.85 241.322 123.031 238.78 124.84 235.98C126.472 233.071 127.697 229.951 128.479 226.709V226.709Z"
              fill="#455A64"
            />
            <path
              d="M81.8669 236.481C82.0418 236.481 82.275 238.487 83.0331 240.877C83.7911 243.268 84.5957 245.099 84.4325 245.18C82.6278 242.649 81.7244 239.586 81.8669 236.481V236.481Z"
              fill="#455A64"
            />
            <path
              d="M133.306 277.693C133.835 278.04 134.447 278.238 135.079 278.265C135.813 278.255 136.527 278.027 137.131 277.612C137.907 277.041 138.456 276.215 138.682 275.279C138.962 274.13 138.962 272.93 138.682 271.781C138.438 270.51 137.948 269.204 137.516 267.769C136.988 266.275 136.809 264.679 136.991 263.104C137.26 261.402 138.368 260.014 138.904 258.44C139.158 257.688 139.258 256.894 139.2 256.103C139.142 255.311 138.927 254.54 138.566 253.834C137.902 252.494 136.763 251.451 135.371 250.906C134.687 250.651 134.017 250.359 133.365 250.032C132.714 249.71 132.161 249.219 131.767 248.609C131.188 247.476 130.938 246.204 131.044 244.936C131.122 243.947 131.083 242.953 130.927 241.974C130.799 241.245 130.499 240.558 130.053 239.968C129.651 239.476 129.097 239.132 128.479 238.988C128.647 238.947 128.823 238.947 128.992 238.988C129.475 239.114 129.91 239.382 130.239 239.758C130.734 240.368 131.085 241.081 131.266 241.845C131.465 242.847 131.535 243.87 131.476 244.889C131.393 246.066 131.644 247.242 132.199 248.283C132.852 249.355 134.204 249.799 135.58 250.347C137.103 250.928 138.352 252.06 139.079 253.519C139.479 254.296 139.72 255.144 139.788 256.015C139.856 256.886 139.75 257.762 139.475 258.591C138.869 260.259 137.796 261.588 137.563 263.116C137.385 264.614 137.549 266.133 138.041 267.559C138.449 268.959 138.927 270.3 139.207 271.617C139.467 272.852 139.431 274.131 139.102 275.349C138.825 276.374 138.192 277.266 137.318 277.868C136.651 278.304 135.863 278.516 135.067 278.475C134.568 278.452 134.085 278.295 133.668 278.02C133.526 277.938 133.403 277.826 133.306 277.693V277.693Z"
              fill="#455A64"
            />
            <path
              d="M124.887 276.865C124.887 276.865 125.038 277.238 125.598 277.506C125.99 277.669 126.425 277.697 126.835 277.585C127.245 277.473 127.605 277.228 127.86 276.888C128.161 276.32 128.303 275.68 128.27 275.038C128.237 274.395 128.031 273.773 127.674 273.238C127.009 271.92 125.691 270.626 125.341 268.702C125.246 268.233 125.254 267.749 125.365 267.284C125.476 266.819 125.686 266.383 125.983 266.008C126.554 265.308 127.231 264.842 127.662 264.27C128.409 263.18 128.702 261.841 128.478 260.539C128.334 259.639 128.112 258.753 127.814 257.892C127.678 257.579 127.584 257.249 127.534 256.912C127.534 256.912 127.755 257.215 128.024 257.81C128.431 258.658 128.725 259.556 128.898 260.48C129.048 261.184 129.055 261.91 128.921 262.617C128.787 263.324 128.514 263.997 128.117 264.597C127.615 265.273 126.951 265.763 126.461 266.381C126.216 266.692 126.042 267.052 125.952 267.437C125.861 267.822 125.856 268.222 125.936 268.609C126.216 270.276 127.452 271.547 128.164 272.982C128.547 273.608 128.75 274.329 128.75 275.063C128.75 275.798 128.547 276.518 128.164 277.145C127.987 277.375 127.763 277.566 127.508 277.704C127.253 277.843 126.972 277.926 126.683 277.95C126.256 278 125.825 277.919 125.446 277.716C124.863 277.296 124.863 276.853 124.887 276.865Z"
              fill="#455A64"
            />
            <path
              d="M79.1734 273.798C78.8159 273.525 78.4946 273.208 78.2171 272.854C77.3848 271.943 76.7001 270.909 76.188 269.787C75.8426 268.985 75.6755 268.117 75.6982 267.244C75.7036 266.21 76.0661 265.209 76.7244 264.411C77.4475 263.583 78.497 263.139 79.1267 262.358C79.4319 261.929 79.6339 261.436 79.7168 260.916C79.7997 260.396 79.7614 259.864 79.6049 259.361C79.3202 258.283 78.8927 257.247 78.3337 256.282C77.7866 255.328 77.3931 254.293 77.1676 253.215C76.9785 252.196 77.1917 251.143 77.7623 250.277C78.2716 249.585 78.8673 248.962 79.5349 248.423C80.4994 247.724 81.2017 246.722 81.529 245.577C81.6081 245.153 81.6081 244.718 81.529 244.294C81.529 244.294 81.6223 244.388 81.7039 244.598C81.8209 244.922 81.8569 245.27 81.8089 245.612C81.5567 246.851 80.8701 247.96 79.8731 248.737C79.2579 249.275 78.7063 249.882 78.2288 250.545C77.7455 251.311 77.5782 252.235 77.7623 253.122C78.0022 254.136 78.3955 255.107 78.9285 256.003C79.5172 257.011 79.9683 258.093 80.2696 259.221C80.442 259.812 80.477 260.434 80.3719 261.041C80.2668 261.647 80.0243 262.221 79.6632 262.72C78.8702 263.653 77.8206 264.061 77.2142 264.772C76.6238 265.463 76.2902 266.336 76.2696 267.244C76.2339 268.04 76.365 268.835 76.6545 269.577C77.1125 270.684 77.725 271.72 78.4737 272.655C78.7433 273.013 78.9776 273.396 79.1734 273.798Z"
              fill="#455A64"
            />
            <path
              d="M127.802 223.537C126.969 222.406 126.015 221.37 124.957 220.446C123.782 219.682 122.526 219.048 121.213 218.557C122.698 218.581 124.136 219.076 125.321 219.971C126.505 220.866 127.374 222.115 127.802 223.537V223.537Z"
              fill="#20419B"
            />
            <path
              d="M129.446 212.376C129.516 212.376 129.901 212.796 130.216 213.694C130.619 214.889 130.656 216.177 130.321 217.392C129.987 218.608 129.296 219.696 128.338 220.516C127.592 221.134 127.032 221.321 126.997 221.262C126.962 221.204 127.429 220.866 128.035 220.213C128.843 219.379 129.428 218.356 129.737 217.238C130.047 216.119 130.071 214.941 129.808 213.811C129.646 213.344 129.525 212.864 129.446 212.376V212.376Z"
              fill="#455A64"
            />
            <path
              opacity="0.3"
              d="M70.2637 355.826C70.2637 355.826 99.2428 383.347 116.467 378.356C133.691 373.365 138.776 360.455 138.776 360.455L139.195 355.791L137.47 354.625L71.1383 349.33L70.2637 355.826Z"
              fill="black"
            />
            <path
              d="M53.1328 330.054L58.7187 315.78L78.7417 328.363L60.8994 355.872C60.8994 355.872 53.6459 346.403 53.1328 330.054Z"
              fill="#EB996E"
            />
            <path
              d="M75.7215 290.474C85.0508 285.6 97.1089 284.2 97.1089 284.2C100.607 291.197 111.651 292.095 118.333 287.314L123.977 283.501C124.756 283.489 125.535 283.524 126.31 283.606C132.746 284.21 138.818 286.872 143.623 291.196C148.429 295.521 151.714 301.279 152.991 307.617C154.104 312.59 154.578 317.684 154.402 322.777C153.668 335.523 140.478 336.678 140.478 336.678C140.478 336.678 139.802 349.26 138.904 359.441C138.711 362.987 137.528 366.409 135.489 369.317C133.451 372.225 130.638 374.504 127.371 375.896C122.752 378.206 117.783 379.738 112.665 380.432C104.676 381.682 96.502 380.954 88.8595 378.311C81.2171 375.668 74.3395 371.191 68.8295 365.272C70.8353 353.144 72.7478 337.436 72.6078 336.724C72.5852 336.648 72.5852 336.567 72.6078 336.491L59.5352 324.888L52.1184 318.66C52.1184 318.66 68.4447 294.521 75.6982 290.556"
              fill="#20419B"
            />
            <path
              d="M91.9309 298.287C90.9445 297.727 89.8349 297.418 88.7006 297.389C87.4885 297.387 86.3117 297.798 85.3654 298.556C84.8278 299.049 84.3288 299.583 83.8727 300.153C83.3524 300.85 82.6064 301.345 81.762 301.553C80.8881 301.663 80.0057 301.428 79.3014 300.9C78.625 300.468 78.0536 300.002 77.4588 299.663C76.9474 299.363 76.3755 299.179 75.7846 299.127C75.1936 299.075 74.5984 299.154 74.042 299.36C73.2626 299.625 72.5723 300.102 72.0479 300.736C71.6863 301.191 71.5581 301.494 71.4998 301.471C71.5436 301.153 71.6681 300.852 71.8613 300.596C72.3499 299.853 73.0597 299.282 73.8904 298.964C74.5049 298.701 75.1717 298.583 75.8391 298.619C76.5065 298.656 77.1565 298.845 77.7387 299.174C78.3918 299.524 78.9748 300.002 79.6162 300.398C80.2003 300.838 80.9303 301.038 81.657 300.958C82.364 300.776 82.9913 300.366 83.4412 299.792C83.9144 299.2 84.4414 298.653 85.0156 298.159C86.0674 297.347 87.373 296.934 88.7006 296.993C89.5779 297.024 90.4345 297.268 91.1962 297.704C91.7326 298.031 91.9542 298.287 91.9309 298.287Z"
              fill="#FAFAFA"
            />
            <path
              d="M105.214 315.78C104.46 314.929 103.506 314.279 102.438 313.891C101.304 313.502 100.074 313.502 98.9397 313.891C98.2791 314.192 97.64 314.539 97.0272 314.928C96.3096 315.42 95.4452 315.65 94.5782 315.581C93.7138 315.406 92.9522 314.9 92.4558 314.17C91.9544 313.552 91.5579 312.923 91.1031 312.41C90.7147 311.962 90.2319 311.607 89.6894 311.369C89.1469 311.131 88.5582 311.016 87.9661 311.033C87.1431 311.042 86.3374 311.271 85.6338 311.698C85.144 312.013 84.9224 312.258 84.8758 312.223C84.8291 312.188 85.0274 311.896 85.5055 311.5C86.3974 310.83 87.506 310.514 88.6172 310.613C89.7284 310.712 90.7636 311.219 91.5229 312.036C92.0243 312.584 92.4325 313.203 92.9106 313.797C93.3307 314.388 93.9534 314.803 94.6599 314.963C95.3954 315.002 96.1237 314.802 96.7356 314.392C97.3742 313.981 98.0496 313.629 98.7531 313.342C100.014 312.911 101.389 312.949 102.625 313.447C103.452 313.75 104.19 314.255 104.77 314.917C105.12 315.395 105.237 315.71 105.214 315.78Z"
              fill="#FAFAFA"
            />
            <path
              d="M134.729 290.241C133.646 289.905 132.497 289.837 131.382 290.043C130.195 290.295 129.129 290.945 128.362 291.885C127.948 292.48 127.578 293.104 127.254 293.751C126.889 294.546 126.264 295.192 125.481 295.582C124.648 295.86 123.74 295.814 122.939 295.454C122.193 295.174 121.528 294.836 120.875 294.626C120.31 294.443 119.711 294.388 119.123 294.465C118.534 294.541 117.969 294.748 117.47 295.069C116.77 295.497 116.198 296.105 115.814 296.83C115.557 297.343 115.487 297.681 115.441 297.669C115.394 297.658 115.441 297.32 115.604 296.725C116.026 295.691 116.817 294.851 117.823 294.366C118.829 293.882 119.978 293.787 121.05 294.101C121.761 294.311 122.438 294.637 123.137 294.894C123.8 295.204 124.556 295.245 125.248 295.011C125.904 294.674 126.429 294.128 126.741 293.46C127.071 292.785 127.462 292.141 127.907 291.535C128.769 290.52 129.963 289.843 131.277 289.623C132.141 289.462 133.032 289.522 133.866 289.798C134.187 289.869 134.484 290.022 134.729 290.241V290.241Z"
              fill="#FAFAFA"
            />
            <path
              d="M121.108 309.366C120.529 308.723 119.816 308.214 119.021 307.873C118.163 307.539 117.216 307.51 116.339 307.792C115.819 307.991 115.316 308.233 114.835 308.515C114.249 308.876 113.558 309.029 112.875 308.946C112.201 308.782 111.616 308.364 111.243 307.78C110.881 307.278 110.601 306.777 110.275 306.369C109.998 306.013 109.645 305.723 109.241 305.521C108.837 305.319 108.394 305.21 107.943 305.203C107.08 305.208 106.239 305.472 105.529 305.961C105.529 305.961 105.645 305.704 106.018 305.413C106.731 304.914 107.603 304.699 108.465 304.809C109.328 304.919 110.118 305.346 110.683 306.007C111.056 306.45 111.359 306.952 111.698 307.407C111.986 307.874 112.44 308.216 112.969 308.363C114.042 308.55 115.021 307.582 116.141 307.267C117.141 306.975 118.213 307.054 119.161 307.488C120.805 308.223 121.178 309.389 121.108 309.366Z"
              fill="#FAFAFA"
            />
            <path
              d="M91.0799 325.482C90.4937 324.842 89.7782 324.333 88.9808 323.99C88.1186 323.667 87.1715 323.65 86.2986 323.943C85.7794 324.145 85.2764 324.387 84.7943 324.666C84.2019 325.043 83.4962 325.2 82.8001 325.109C82.1309 324.941 81.551 324.524 81.1792 323.943C80.806 323.442 80.5261 322.94 80.2112 322.544C79.9326 322.189 79.579 321.901 79.1757 321.699C78.7725 321.498 78.3296 321.388 77.8789 321.377C77.0156 321.377 76.1729 321.641 75.465 322.136C75.465 322.136 75.5699 321.879 75.9548 321.587C76.5129 321.177 77.1861 320.953 77.8789 320.946C78.4036 320.926 78.9259 321.027 79.4052 321.241C79.8845 321.456 80.308 321.778 80.6427 322.182C81.0159 322.625 81.3074 323.127 81.6573 323.582C81.94 324.049 82.3905 324.391 82.9167 324.538C83.9896 324.724 84.9808 323.756 86.1004 323.442C87.0971 323.15 88.1658 323.229 89.109 323.663C90.765 324.34 91.1382 325.506 91.0799 325.482Z"
              fill="#FAFAFA"
            />
            <path
              d="M119.149 325.856C118.398 325.419 117.556 325.164 116.689 325.109C115.768 325.076 114.864 325.355 114.123 325.902C113.703 326.259 113.313 326.649 112.957 327.068C112.519 327.598 111.915 327.964 111.243 328.106C110.547 328.157 109.859 327.94 109.318 327.5C108.805 327.15 108.385 326.765 107.942 326.485C107.564 326.242 107.137 326.086 106.692 326.027C106.246 325.969 105.793 326.01 105.365 326.147C104.55 326.434 103.84 326.958 103.324 327.651C103.324 327.651 103.324 327.371 103.616 326.963C104.016 326.397 104.583 325.969 105.237 325.739C105.726 325.55 106.252 325.477 106.774 325.525C107.296 325.574 107.8 325.743 108.246 326.019C108.735 326.31 109.178 326.695 109.657 327.01C110.076 327.361 110.615 327.536 111.161 327.5C112.234 327.336 112.852 326.1 113.808 325.436C114.662 324.84 115.699 324.567 116.735 324.666C117.201 324.67 117.66 324.779 118.078 324.985C118.496 325.191 118.862 325.489 119.149 325.856V325.856Z"
              fill="#FAFAFA"
            />
            <path
              d="M109.878 338.66C109.121 338.239 108.282 337.984 107.418 337.914C106.494 337.881 105.586 338.16 104.84 338.707C104.424 339.066 104.034 339.456 103.674 339.873C103.238 340.404 102.633 340.77 101.96 340.911C101.267 340.96 100.58 340.748 100.036 340.316C99.5227 339.954 99.1029 339.569 98.6598 339.301C98.2837 339.054 97.8569 338.894 97.4109 338.833C96.9649 338.773 96.5109 338.813 96.0825 338.951C95.2653 339.234 94.5534 339.759 94.0417 340.456C94.0678 340.204 94.1649 339.966 94.3216 339.768C94.8366 339.051 95.6035 338.555 96.4686 338.38C97.3337 338.205 98.233 338.363 98.9863 338.823C99.4877 339.115 99.9309 339.499 100.409 339.814C100.829 340.165 101.367 340.341 101.913 340.304C102.986 340.141 103.604 338.905 104.561 338.24C105.416 337.648 106.452 337.375 107.488 337.47C107.949 337.481 108.403 337.592 108.816 337.798C109.229 338.004 109.592 338.298 109.878 338.66V338.66Z"
              fill="#FAFAFA"
            />
            <path
              d="M90.2982 370.449C89.7018 369.816 88.9787 369.315 88.1758 368.98C87.3079 368.674 86.3616 368.674 85.4937 368.98C84.9769 369.193 84.4778 369.446 84.001 369.738C83.4219 370.105 82.7343 370.261 82.0535 370.181C81.3746 370.021 80.7848 369.603 80.4092 369.015C80.036 368.525 79.7445 368.024 79.418 367.627C79.1377 367.275 78.7837 366.988 78.3808 366.786C77.9779 366.585 77.5359 366.474 77.0856 366.461C76.2207 366.473 75.3811 366.754 74.6833 367.266C74.6833 367.266 74.7883 366.997 75.1615 366.694C75.7208 366.284 76.3925 366.057 77.0856 366.041C77.6066 366.011 78.1276 366.1 78.6087 366.302C79.0898 366.504 79.5183 366.814 79.8611 367.207C80.2343 367.651 80.5375 368.152 80.8873 368.595C81.1801 369.059 81.639 369.392 82.1701 369.528C83.243 369.703 84.2109 368.723 85.3304 368.362C86.3272 368.062 87.3985 368.128 88.3507 368.548C89.96 369.306 90.3565 370.461 90.2982 370.449Z"
              fill="#FAFAFA"
            />
            <path
              d="M113.213 377.213C112.471 376.777 111.644 376.506 110.788 376.42C109.868 376.376 108.96 376.643 108.211 377.178C107.791 377.534 107.401 377.924 107.044 378.344C106.599 378.864 105.991 379.218 105.318 379.347C104.625 379.397 103.939 379.175 103.406 378.729C102.893 378.368 102.473 377.971 102.042 377.691C101.667 377.442 101.241 377.278 100.796 377.211C100.35 377.145 99.8958 377.177 99.4643 377.306C98.6472 377.583 97.9321 378.099 97.4119 378.787C97.4119 378.787 97.4119 378.507 97.7034 378.111C98.1171 377.566 98.6864 377.159 99.336 376.945C99.83 376.771 100.357 376.709 100.878 376.766C101.398 376.822 101.9 376.995 102.345 377.271C102.835 377.575 103.278 377.959 103.744 378.286C104.164 378.638 104.701 378.821 105.248 378.799C106.321 378.636 106.963 377.423 107.919 376.77C108.783 376.189 109.822 375.929 110.858 376.035C111.312 376.049 111.758 376.162 112.165 376.365C112.572 376.568 112.93 376.858 113.213 377.213Z"
              fill="#FAFAFA"
            />
            <path
              d="M37.6929 280.795C38.3926 281.623 64.0365 315.908 64.0365 315.908L60.433 317.96L34.1128 284.142L37.6929 280.795Z"
              fill="#263238"
            />
            <path
              d="M137.761 305.285C137.761 305.285 113.715 346.765 112.106 347.523C108.899 349.027 72.0013 317.133 68.4794 314.089C68.0013 313.436 66.9984 312.072 64.981 309.354C64.981 309.354 62.0772 296.025 58.9636 297.051C55.8499 298.078 60.6662 303.827 58.6487 308.888C56.6313 313.949 47.3603 301.891 46.9871 300.795L44.6548 297.67C44.6548 297.67 42.1475 304.771 42.3225 307.745C42.8589 317.249 51.85 328.549 51.85 328.549C51.85 328.549 88.3975 374.333 116.059 375.884C124.024 376.339 139.242 353.4 148.711 336.444C154.425 326.205 151.767 315.815 142.297 308.9L137.761 305.285Z"
              fill="#FFBE9D"
            />
            <path
              d="M46.8356 309.366C45.2573 307.263 44.072 304.893 43.3372 302.369C43.4305 302.369 44.1185 303.943 45.0864 305.867C46.0543 307.792 46.9173 309.319 46.8356 309.366Z"
              fill="#EB996E"
            />
            <path
              d="M136.63 293.693C135.685 294.264 113.307 335.779 113.307 335.779L142.099 358.321C146.5 347.388 150.315 336.227 153.528 324.888C154.6 320.818 154.052 309.937 152.536 305.739C150.391 299.78 147.149 301.261 142.286 297.774L136.63 293.693Z"
              fill="#20419B"
            />
            <g opacity="0.7">
              <path
                d="M146.087 293.693C146.087 293.693 145.808 293.53 145.271 293.261C144.49 292.864 143.616 292.682 142.741 292.737C141.36 292.866 140.051 293.411 138.986 294.299C137.56 295.439 136.311 296.783 135.277 298.288C134.01 300.169 132.842 302.115 131.779 304.118C130.613 306.229 129.283 308.491 127.93 310.87C125.213 315.617 122.216 320.841 119.091 326.322C117.167 329.646 115.313 332.876 113.563 335.908L113.493 335.535L132.968 350.928L138.624 355.464L140.117 356.701C140.299 356.834 140.467 356.987 140.618 357.155C140.419 357.051 140.232 356.925 140.058 356.782L138.496 355.616L132.735 351.208L113.132 336.048L112.922 335.885L113.12 335.605C114.869 332.573 116.724 329.342 118.624 326.007C121.773 320.538 124.782 315.337 127.511 310.591L131.406 303.862C132.468 301.858 133.636 299.912 134.904 298.031C135.986 296.486 137.3 295.117 138.799 293.973C139.928 293.074 141.302 292.538 142.741 292.433C143.653 292.401 144.556 292.632 145.341 293.098C145.545 293.211 145.74 293.34 145.924 293.483C146.041 293.623 146.099 293.681 146.087 293.693Z"
                fill="black"
              />
            </g>
            <path
              d="M137.493 332.619C136.917 331.973 136.203 331.463 135.405 331.126C134.547 330.794 133.602 330.761 132.723 331.033C132.203 331.237 131.7 331.483 131.219 331.768C130.632 332.125 129.943 332.276 129.26 332.199C128.586 332.035 128.001 331.617 127.627 331.033C127.266 330.532 126.986 330.03 126.659 329.622C126.382 329.266 126.029 328.976 125.625 328.774C125.222 328.572 124.778 328.464 124.327 328.456C123.464 328.461 122.623 328.725 121.913 329.214C121.913 329.214 122.03 328.957 122.403 328.654C122.971 328.257 123.645 328.038 124.339 328.024C124.862 328.004 125.382 328.105 125.86 328.32C126.337 328.534 126.758 328.856 127.091 329.26C127.464 329.704 127.767 330.205 128.105 330.66C128.397 331.124 128.849 331.465 129.376 331.616C130.449 331.803 131.429 330.835 132.548 330.52C133.548 330.225 134.62 330.299 135.569 330.73C137.19 331.465 137.609 332.631 137.493 332.619Z"
              fill="#FAFAFA"
            />
            <path
              d="M150.647 327.255C150.276 326.466 149.724 325.775 149.038 325.238C148.3 324.692 147.401 324.409 146.484 324.433C145.93 324.497 145.38 324.598 144.84 324.736C144.177 324.923 143.471 324.882 142.834 324.619C142.229 324.271 141.782 323.703 141.586 323.033C141.376 322.45 141.236 321.867 141.038 321.424C140.865 321.007 140.602 320.634 140.267 320.332C139.932 320.029 139.534 319.805 139.102 319.675C138.27 319.447 137.388 319.475 136.572 319.757C136.572 319.757 136.747 319.535 137.19 319.36C137.839 319.113 138.55 319.081 139.219 319.267C139.73 319.388 140.207 319.626 140.611 319.962C141.016 320.298 141.338 320.722 141.551 321.203C141.784 321.727 141.936 322.287 142.146 322.824C142.296 323.349 142.643 323.795 143.114 324.071C144.093 324.549 145.295 323.885 146.461 323.873C147.5 323.872 148.507 324.235 149.306 324.899C149.692 325.166 150.013 325.516 150.245 325.924C150.478 326.332 150.615 326.787 150.647 327.255V327.255Z"
              fill="#FAFAFA"
            />
            <path
              d="M151.324 310.089C150.946 309.307 150.396 308.621 149.714 308.083C148.979 307.533 148.078 307.249 147.16 307.279C146.604 307.331 146.054 307.433 145.516 307.582C144.853 307.77 144.144 307.725 143.51 307.453C142.909 307.107 142.462 306.544 142.263 305.879C142.053 305.296 141.913 304.713 141.714 304.258C141.538 303.844 141.273 303.473 140.939 303.171C140.604 302.869 140.209 302.643 139.779 302.509C138.946 302.281 138.064 302.31 137.248 302.591C137.248 302.591 137.423 302.381 137.866 302.194C138.516 301.953 139.226 301.92 139.895 302.101C140.404 302.228 140.878 302.468 141.282 302.803C141.686 303.138 142.009 303.56 142.228 304.037C142.461 304.561 142.612 305.133 142.822 305.658C142.972 306.186 143.318 306.637 143.79 306.917C144.77 307.384 145.971 306.719 147.137 306.719C148.178 306.706 149.189 307.071 149.983 307.745C150.367 308.011 150.686 308.36 150.918 308.765C151.15 309.171 151.289 309.623 151.324 310.089V310.089Z"
              fill="#FAFAFA"
            />
            <path
              d="M144.956 349.599C144.574 348.82 144.024 348.135 143.347 347.593C142.608 347.041 141.703 346.757 140.782 346.788C140.229 346.841 139.683 346.942 139.149 347.091C138.486 347.278 137.78 347.237 137.143 346.975C136.543 346.621 136.098 346.055 135.895 345.389C135.674 344.806 135.546 344.223 135.336 343.768C135.161 343.354 134.899 342.983 134.566 342.681C134.234 342.379 133.84 342.153 133.411 342.018C132.579 341.783 131.694 341.816 130.881 342.112C130.881 342.112 131.056 341.89 131.499 341.704C132.323 341.412 133.227 341.439 134.032 341.78C134.837 342.121 135.485 342.751 135.849 343.546C136.094 344.071 136.245 344.642 136.455 345.167C136.604 345.692 136.945 346.142 137.411 346.427C138.403 346.893 139.604 346.228 140.77 346.228C141.811 346.216 142.822 346.58 143.615 347.255C143.999 347.521 144.319 347.869 144.551 348.275C144.783 348.68 144.922 349.133 144.956 349.599V349.599Z"
              fill="#FAFAFA"
            />
          </svg>

          <p class="mt-4 fs-16 fw-500">{{ $t('chat_features.lbl_start2_msg') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBox from '@/components/Common/Image.vue'
import Conversation from '@/components/Chats/Conversation.vue'
import appUtils from '@/utils/appUtils'
import { isNil } from 'lodash'

export default {
  name: 'ChatDetail',
  components: { Conversation, ImageBox },
  props: ['activeConversation', 'propName', 'circleID'],
  data () {
    return {
      appUtils
    }
  },

  methods: {
    async goToMeet () {
      if (!this.meetId) {
        alert('Meet Circle không tồn tại')
        return
      }
      let data = await this.$rf
        .getRequest('DoctorRequest')
        .showMeetRoom(this.meetId)
      if (data.data) {
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.meet.hodo.com.vn/#/'
        } else {
          dm = 'https://meet.hodo.com.vn/#/'
        }
        let token = appUtils.getLocalToken()
        let routeData =
          dm + '_app/' + data.data.room?.room_name + '?token=' + token
        window.open(routeData, '_blank')
      }
    },
    handleViewCircleDetail () {
      if (!this.circleID || isNil(this.circleID)) return

      this.$router
        .push({
          name: 'CircleDetail',
          params: { id: this.circleID }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
/* #circleChat {
  height: 100%;
} */

.circle-chat-content {
  height: calc(100% - 38px);
}

.mt-custom {
  margin-top: 100px;
  margin-right: 100px;
}

.open-chat-att {
  border-radius: 10px;
  cursor: pointer;
}
</style>
