<template>
  <div class="flex pr-1" :class="user_info.isLocal ? 'justify-end' : ''" v-show="message">
    <div
      class="d-flex cursor-pointer max-w-full"
      :class="user_info.isLocal ? `flex-row-reverse` : ``"
    >
      <div :class="user_info.isLocal ? 'ml-2' : 'mr-2'">
        <div v-if="user_info && !loading_info" @click="goToProfile()">
          <!-- <span
            v-if="user_info.avatar"
            class="avatar rounded-lg"
            :style="
            'background-image: url(' + getImageURL(user_info.avatar) + ')'
          "
          ></span>-->
          <ImageBox
            v-if="user_info.avatar"
            class="avatar rounded-lg border"
            avatar
            :source="getImageURL(user_info.avatar)"
            :alt="(user_info.name && user_info.name.charAt(0)) || `HODO`"
          />
          <span
            v-if="!user_info.avatar"
            class="avatar rounded-lg border text-white bg-pri"
          >{{ (user_info.name && user_info.name.charAt(0)) || "HODO" }}</span>
        </div>
        <div class="spinner-border" role="status" v-show="loading_info">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        class="w-100 flex flex-column p-2 border rounded-lg transition-all duration-300 hover:bg-opacity-50"
        :class="user_info.isLocal ? 'bg-blue-300 bg-opacity-25 ml-12' : 'bg-gray-300 bg-opacity-10 mr-12'"
        v-show="!loading_info"
      >
        <div class="participant-info items-end mb-2">
          <p
            class="mb-0 fs-16 fw-700 txt-black mr-2"
            @click="goToProfile()"
          >{{ user_info && user_info.name }}</p>
          <p
            class="mb-0 fs-12 txt-grey-600"
          >{{ message && message.dateCreated && appUtils.isToday(message.dateCreated) ? appUtils.formatSimpleTime(message.dateCreated) : appUtils.formatDateTime(message.dateCreated) }}</p>
        </div>
        <div
          class="participant-message"
          v-if="message && message.state && message.state.body"
          v-html="message && message.state && message.state.body"
          v-chat-content
        ></div>
        <div v-if="is_media">
          <div v-for="(m, i) in this.message.state.medias" :key="i + '-' + getUnixTime()">
            <MediaMessage :media="m" @showImage="showImage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
import MediaMessage from './MediaMessage.vue'
import ImageBox from '@/components/Common/Image.vue'

export default {
  name: 'MessageBubble',
  props: ['message'],
  components: { MediaMessage, ImageBox },
  data () {
    return {
      is_media: false,
      failed_load_media: false,
      media_url: '',
      user_info: {},
      loading_info: true,
      doctor_id: null,
      appUtils
    }
  },
  mounted () {
    if (this.message) {
      this.is_media =
        this.message?.type === 'media' ||
        this.message?.media ||
        this.message?.medias?.length
      this.getUserInfo()
    }
  },
  watch: {
    message (m) {
      if (m) this.getUserInfo()
    }
  },
  methods: {
    async goToProfile () {
      const user_id = this.user_info && this.user_info.user_id
      if (!user_id) return
      const res = await this.$rf
        .getRequest('AuthRequest')
        .getDoctor({ user_id })
      this.doctor_id = res.data
      this.$router.push({
        name: 'DoctorProfile',
        params: { id: this.doctor_id.id }
      })
    },
    async getUserInfo () {
      try {
        this.loading_info = true

        let userInfo = await this.$conversationsClient.getUser(
          this.message.author
        )

        let newUserInfo = {
          name: userInfo?.friendlyName,
          avatar: null,
          user_id: null
        }
        if (userInfo?.attributes && typeof userInfo?.attributes === 'object') {
          newUserInfo.avatar = userInfo?.attributes?.avatar
          newUserInfo.user_id = userInfo?.attributes?.user_id
          if (!userInfo.name && userInfo?.attributes?.name) {
            newUserInfo.name = userInfo?.attributes?.name
          }
        }
        this.user_info = {
          ...newUserInfo,
          isLocal: newUserInfo?.user_id === this.$user?.id || false
        }
      } catch (error) {
      } finally {
        this.loading_info = false
      }
    },
    formatHMa (d) {
      return window.moment(d).format('hh:mm a')
    },
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    getUnixTime () {
      return new Date().getTime()
    },
    showImage (p) {
      this.$emit('showImage', p)
    }
  }
}
</script>

<style scoped>
.participant-info {
  display: flex;
  align-items: center;
}
.participant-message {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  word-break: break-word;
}
</style>
