<template>
  <div
    class="d-flex justify-content-between align-items-center cursor-pointer item ml-3"
    :class="
      activeConversation && activeConversation.sid === single.twilio_conv_id
        ? 'active'
        : ''
    "
  >
    <div class="d-flex justify-content-start align-items-center">
      <div class="mr-3">
        <ImageBox
          v-if="single && ((single.to && single.to.avatar) || single.avatar)"
          class="avatar avatar-sm rounded-md border"
          avatar
          :source="getImageURL(single)"
          :alt="((single.to && single.to.name.charAt(0)) || single.name.charAt(0)) || `HODO`"
        />

        <span v-else class="avatar avatar-sm rounded-md bg-pri text-white border">
          {{
          single &&
          ((single.to && single.to.name) ? single.to.name.split(" ").pop().charAt(0) : (single.to && single.to.email) ? single.to.email.charAt(0) : single.name
          ? single.name.split(" ").pop().charAt(0)
          : single.email
          ? single.email.charAt(0)
          : "")
          }}
        </span>
      </div>
      <p
        v-if="!single"
        class="mb-0 txt-black leading-none"
        :class="unread ? 'fs-16 fw-700' : 'fs-14 fw-500'"
      >{{ $user.name }}</p>
      <p
        class="mb-0 txt-black leading-none"
        :class="unread ? 'fs-16 fw-700' : 'fs-14 fw-500'"
      >{{ single && ((single.to && single.to.name) || (single.to && single.to.email) || single.name || single.email) }}</p>
    </div>
    <span class="noti-count" v-if="unread">{{ unread }}</span>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
import ImageBox from '@/components/Common/Image.vue'

export default {
  name: 'ChatSingleListItem',
  props: ['single', 'activeConversation'],
  components: { ImageBox },
  data () {
    return {
      count: 0,
      appUtils
      // chatSingle: this.single || {}
    }
  },
  watch: {
    // single: {
    //   handler (data) {
    //     console.log(data)
    //     this.chatSingle = { ...data }
    //   },
    //   deep: true,
    //   immediate: true
    // }
    unread (data) {
      console.log(data)
    }
  },
  mounted () {
    // this.chatSingle = { ...this.single }
  },
  computed: {
    unread () {
      return this.single?.unread || this.single?.unread_count || 0
    }
  },
  methods: {
    getImageURL (data) {
      const image = data?.to?.avatar || data?.avatar
      return appUtils.getImageURL(image)
    }
  }
}
</script>

<style scoped>
.noti-count {
  width: 24px;
  height: 24px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d92d20;
  text-align: center;
  color: white;
  border-radius: 12px;
  padding-top: 2px;
}
.item {
  padding: 10px;
}
.item:hover,
.active {
  background-color: #e4e7ec;
}
</style>
