<template>
    <div class="modal fade" id="modal-line-chart">
        <div class="modal-dialog modal-full-width modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header bg-white">
                    <h4 class="modal-title txt-pri ">{{name || 'Biểu đồ'}}</h4>
                    <a href="javascript:;" class="mr-3" type="button" data-dismiss="modal" aria-label="Close">
                        <img src="../../../public/assets/images/icon/x-gray.svg" alt="" width="30" height="30">
                    </a>
                </div>
                <div class="modal-body">
                    <div v-if="chart_data && chart_data.total_data" class="w-100 d-flex justify-content-center align-items-center" style="overflow-x: auto; overflow-y: hidden;">
                        <LineChart :style="[getCss(), raw_size]" :chart-data="chart.datas" :options="chart.option"></LineChart>
                    </div>
                    <div v-if="!chart_data || !chart_data.total_data" class="d-flex justify-content-center align-items-center">
                        <img src="../../../public/assets/images/chart-blur.png" class="relative">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>
<script>
import LineChart from '../../utils/LineChart.js'
export default {

  name: 'ModalFullLine',
  components: { LineChart },
  props: ['chart_data', 'name'],
  watch: {
    chart_data: function (_new, old) {
      this.chart = _new
    }
  },
  mounted () {
    let self = this
    self.changeScreen()
    window.$(window).on('resize', function () {
      self.changeScreen()
    })
  },
  data () {
    return {
      chart: null,
      raw_size: {}
    }
  },
  methods: {
    changeScreen () {
      let self = this
      var size = window.$('body').width()
      let width = size * 0.8
      let height = size * 0.4
      self.raw_size = {
        'min-width': width + 'px',
        'min-height': height + 'px'
      }
    },
    getCss () {
      let self = this
      var size = window.$('body').width()
      let width = size * 0.8
      // let height = size * 0.4
      let _width = 0
      let css = { position: 'relative', background: '#fff', 'padding-top': '30px', 'border-radius': '12px' }
      let chart = self.chart
      if (chart && chart.datas && chart.datas.labels && chart.datas.labels.length) {
        _width = chart.datas.labels.length * 100
      }
      if (_width > width) {
        width = _width
      }
      css.width = width + 'px'
      return css
    }
  }
}
</script>
<style lang="css" scoped>
a:hover {
    text-decoration: none;
}
</style>